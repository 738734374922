import { Controller } from '@hotwired/stimulus'
// @ts-expect-error
import Rails from '@rails/ujs'
// @ts-expect-error
import Sortable from 'sortablejs'

export default class extends Controller {
  static values = {
    url: String
  }

  declare urlValue: string

  sortable: Sortable

  connect (): void {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      handle: '.sortable-handle',
      onEnd: this.end.bind(this)
    })
  }

  end (event: any): void {
    const id = event.item.dataset.id
    const data = new FormData()
    data.append('position', `${parseInt(event.newIndex) + 1}`)

    Rails.ajax({
      url: this.urlValue.replace(':id', id),
      type: 'PATCH',
      data
    })
  }
}
