import { Controller } from '@hotwired/stimulus'
import Chart, { ChartItem, ChartConfiguration } from 'chart.js/auto'

export default class extends Controller {
  chart: Chart

  static values = {
    rows: Array
  }

  declare rowsValue: any[]

  connect (): void {
    const configuration: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: this.rowsValue.map(({ name }) => name),
        datasets: [
          {
            data: this.rowsValue.map(({ count }) => count),
            backgroundColor: this.rowsValue.map(({ color }) => color)
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }

    this.chart = new Chart(this.element as ChartItem, configuration)
  }

  disconnect (): void {
    this.chart.destroy()
  }
}
