import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    refreshTimeout: { type: Number, default: 5000 },
    isFinished: { type: Boolean, default: true }
  }

  declare readonly refreshTimeoutValue: number
  declare readonly isFinishedValue: boolean
  declare refreshTimeoutId: NodeJS.Timeout

  connect (): void {
    this.startRefreshTimeout()
  }

  disconnect (): void {
    this.clearRefreshTimeout()
  }

  onTurboStreamMessage (event: any): void {
    const { targetId } = event.detail

    if (targetId === this.element.id) {
      this.clearRefreshTimeout()
      this.startRefreshTimeout()
    }
  }

  private startRefreshTimeout (): void {
    this.clearRefreshTimeout()

    if (!this.isFinishedValue) {
      this.refreshTimeoutId = setTimeout(() => {
        this.refreshPage()
      }, this.refreshTimeoutValue)
    }
  }

  private clearRefreshTimeout (): void {
    if (this.refreshTimeoutId !== undefined) {
      clearTimeout(this.refreshTimeoutId)
    }
  }

  private refreshPage (): void {
    window.location.reload()
  }
}
