import { Controller } from '@hotwired/stimulus'
import DynamicSelect, { Options } from '../modules/dynamic_select'

export default class extends Controller {
  static targets = ['status', 'statusSelect']
  static values = { options: Array, default: [] }

  declare readonly statusTargets: HTMLInputElement[]

  declare selectboxes: DynamicSelect[]
  declare optionsValue: Options

  initialize (): void {
    this.collectOptions()
  }

  collectOptions (): void {
    this.optionsValue = this.statusTargets
      .filter(target => !target.classList.contains('hidden'))
      .map(status => {
        const nameInput = status.querySelector('input[id$=_name]') as HTMLInputElement
        const indexInput = status.querySelector('input[id$=_index]') as HTMLInputElement

        return {
          id: indexInput.value,
          name: nameInput.value.length > 0 ? nameInput.value : ' '
        }
      }).filter(e => e) as Options
  }

  optionsValueChanged (): void {
    if (this.selectboxes !== undefined && this.optionsValue.length > 0) {
      this.selectboxes.forEach(selectbox => {
        this.redrawSelectbox(selectbox)
      })
    }
  }

  redrawSelectbox (selectbox: DynamicSelect): void {
    selectbox.reset([
      { id: '', name: selectbox.element.getAttribute('data-prompt') ?? ' ' },
      ...this.optionsValue
    ])
  }

  statusSelectTargetConnected (element: HTMLSelectElement): void {
    const selectbox = new DynamicSelect(element)
    this.redrawSelectbox(selectbox)

    if (this.selectboxes === undefined) {
      this.selectboxes = []
    }

    this.selectboxes.push(selectbox)
  }

  statusTargetDisconnected (): void {
    this.collectOptions()
  }
}
