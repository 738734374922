import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['widgetType', 'groupBy']

  static values = {
    numberSelected: Boolean
  }

  declare readonly widgetTypeTarget: HTMLSelectElement
  declare readonly groupByTarget: HTMLInputElement
  declare numberSelectedValue: boolean

  initialize (): void {
    this.changeType()
  }

  changeType (): void {
    this.numberSelectedValue = this.widgetTypeTarget.value === 'number'
  }

  numberSelectedValueChanged (): void {
    const event = new Event('toggle:toggle')

    this.element.dispatchEvent(event)
    this.groupByTarget.disabled = this.numberSelectedValue
  }

  reset (): void {
    (this.element as HTMLFormElement).reset()
    this.changeType()
  }
}
