import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['readRolesInput', 'writeRolesInput']
  static values = {
    allToRead: { type: Boolean, default: false },
    allToWrite: { type: Boolean, default: false }
  }

  declare readonly readRolesInputTarget: HTMLSelectElement
  declare readonly writeRolesInputTarget: HTMLSelectElement
  declare allToReadValue: boolean
  declare allToWriteValue: boolean

  toggleRead (): void {
    this.allToReadValue = !this.allToReadValue
    this.toggleSelectRequired(this.readRolesInputTarget, !this.allToReadValue)
  }

  toggleWrite (): void {
    this.allToWriteValue = !this.allToWriteValue
    this.toggleSelectRequired(this.writeRolesInputTarget, !this.allToWriteValue)

    if (this.allToWriteValue) {
      this.assignWritePermissonsToReadPermittedRoles()
    }
  }

  changeReadRoles (_: Event): void {
    if (this.allToWriteValue) {
      this.assignWritePermissonsToReadPermittedRoles()
    }
  }

  assignWritePermissonsToReadPermittedRoles (): void {
    const selectedOptions = this.readRolesInputTarget.selectedOptions
    const selectedValues = Array.from(selectedOptions).map(option => option.value)

    Array.from(this.writeRolesInputTarget.options).forEach(option => {
      option.selected = selectedValues.includes(option.value)
    })
  }

  private toggleSelectRequired (input: HTMLSelectElement, value: boolean): void {
    if (value) {
      input.setAttribute('required', 'required')
    } else {
      input.removeAttribute('required')
    }
  }
}
