import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label', 'hidden']

  declare readonly labelTarget: HTMLInputElement
  declare readonly hiddenTarget: HTMLInputElement

  select (event: Event): void {
    const target = event.target as HTMLElement

    const name = target.getAttribute('data-selectable-dropdown-name')
    const value = target.getAttribute('data-selectable-dropdown-value')

    if (name !== null && name !== '' && value !== null && value !== '') {
      this.labelTarget.textContent = name
      this.hiddenTarget.value = value
    }

    const selected = this.element.querySelector('.selected-dropdown-item')
    if (selected !== null) {
      this.swap(selected, target)
    }
  }

  swap (item: Element, other: HTMLElement): void {
    const itemClassList = [...item.classList].join(' ')

    // @ts-expect-error
    item.classList = other.classList
    item.querySelectorAll('.selected-icon').forEach((icon) => {
      icon.classList.add('hidden')
    })

    // @ts-expect-error
    other.classList = itemClassList
    other.querySelectorAll('.selected-icon').forEach((icon) => {
      icon.classList.remove('hidden')
    })
  }
}
