import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  declare readonly inputTarget: HTMLInputElement

  copy ({ target }: { target: HTMLDivElement }): void {
    this.inputTarget.value = target.innerHTML
  }
}
