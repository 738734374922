import SelectableController from './selectable_controller'

export default class extends SelectableController {
  static values = {
    url: { type: String, default: '/fields' }
  }

  settings = {
    plugins: ['virtual_scroll', 'remove_button']
  }

  buildUrl (query: string): string {
    const joinOperator = this.urlValue.includes('?') ? '&' : '?'

    return `${this.urlValue}${joinOperator}page=${this.currentPage}` +
      (query === '' ? '' : `&term=${encodeURIComponent(query)}`)
  }
}
