import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'button']

  declare readonly containerTarget: HTMLDivElement
  declare buttonTarget: HTMLInputElement

  connect (): void {
    this.containerTarget.addEventListener('scroll', this.checkScrollPosition.bind(this))
  }

  checkScrollPositionOnOpenModal (): void {
    // workaround to have correct 'containerTarget' data when modal is just open
    setTimeout(() => {
      this.checkScrollPosition()
    }, 50)
  }

  checkScrollPosition (): void {
    const isScrolledToBottom = this.containerTarget.scrollHeight - this.containerTarget.clientHeight <= this.containerTarget.scrollTop + 1
    const isContentSmallerThanContainer = this.containerTarget.scrollHeight <= this.containerTarget.clientHeight

    this.enableButton(isScrolledToBottom || isContentSmallerThanContainer)
  }

  private enableButton (condition: boolean): void {
    if (condition) {
      this.buttonTarget.removeAttribute('disabled')
    }
  }
}
