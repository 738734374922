import { Controller } from '@hotwired/stimulus'
import BulkSelect from './bulk_select_controller'

export default class extends Controller {
  static outlets = ['bulk-select']

  declare readonly bulkSelectOutlet: BulkSelect

  deselectAll (e: Event): void {
    this.bulkSelectOutlet.deselectAll()
  }
}
