import { Controller } from '@hotwired/stimulus'

export interface ResultOptions {
  no_of_extinguisher: number
  race_pass_poster_available: string
  maintenance_card: string
}

export default class extends Controller {
  static targets = ['input', 'list', 'template']

  static values = {
    fileIdPlaceholder: { type: String, default: 'file_id' }
  }

  declare readonly fileIdPlaceholderValue: string
  declare readonly inputTarget: HTMLInputElement
  declare readonly listTarget: HTMLElement
  declare readonly templateTarget: HTMLTemplateElement

  files: any

  connect (): void {
    document.addEventListener('slideover:opened', this.revertUnsaved.bind(this), { capture: true })
  }

  disconnect (): void {
    document.removeEventListener('slideover:opened', this.revertUnsaved.bind(this), { capture: true })
  }

  initialize (): void {
    this.clear()
  }

  select ({ dataTransfer, target }: { dataTransfer: DataTransfer | null, target: EventTarget }): void {
    this.addFiles(dataTransfer ?? target)
  }

  deselect ({ params: { index } }: { params: { index: any } }): void {
    const element = document.getElementById(index)

    if (element != null) {
      element.classList.add('hidden')
      this.removeElement(element)
    }

    delete this.files[index] // eslint-disable-line @typescript-eslint/no-dynamic-delete

    this.updateInput()
  }

  selectSingleFile ({ dataTransfer, target }: { dataTransfer: DataTransfer | null, target: EventTarget }): void {
    this.clear()
    this.listTarget.innerHTML = ''
    this.addFiles(dataTransfer ?? target, 1)
  }

  clear (): void {
    this.files = {}
  }

  dragOver (e: Event): void {
    e.preventDefault()
  }

  private removeElement (element: HTMLElement): void {
    const formElement = this.inputTarget.closest('form')

    if (formElement != null) {
      const handler = (): void => {
        element.remove()
        formElement.removeEventListener('submit', handler)
      }

      formElement.addEventListener('submit', handler)
    }
  }

  private revertUnsaved (): void {
    this.listTarget.querySelectorAll('.hidden').forEach((element) => {
      element.classList.remove('hidden')
    })
  }

  private addFiles (input: any, limit: number = input.files.length): void {
    let i = 0
    while (i < limit) {
      const file = input.files[i]

      this.add(file)
      i++
    }

    this.updateInput()
  }

  private extractFilenameFromUrl (url: string): string {
    const parts = url.split('/')
    return decodeURIComponent(parts.pop() ?? 'file')
  }

  private async convertUrlToFile (url: string, filename: string): Promise<void> {
    const file = await this.urlToFile(url, filename)
    this.add(file, url)
  }

  private async urlToFile (url: string, filename: string): Promise<File> {
    const response = await fetch(url)
    const blob: Blob = await response.blob()
    return new File([blob], filename)
  }

  private add (file: File, originalUrl?: string, skipInsert?: Boolean): void {
    const id = `${file.name}-${new Date().getTime()}`
    const fileHTML = this.templateFor(id, file, originalUrl)

    if (skipInsert == null) {
      this.listTarget.insertAdjacentHTML('beforeend', fileHTML)
    }
    this.files[id] = file
  }

  private templateFor (id: string, file: File, originalUrl?: string): string {
    const size =
      file.size > 1024
        ? file.size > 1048576
          ? `${Math.round(file.size / 1048576)}MB`
          : `${Math.round(file.size / 1024)}KB`
        : `${file.size}B`

    if (originalUrl === '' || originalUrl === null || originalUrl === undefined) {
      this.templateTarget.content.querySelector('a[href="file_url"]')?.classList.add('hidden')
    }

    const template = this.templateTarget.innerHTML
      .replace(/file_preview/, '<img src="' + URL.createObjectURL(file) + '" />')
      .replace(new RegExp(this.fileIdPlaceholderValue, 'g'), id)
      .replace(/file_name/g, file.name)
      .replace(/file_size/g, size)
      .replace(/file_url/g, originalUrl ?? '/')

    return template
  }

  private updateInput (): void {
    const data = new DataTransfer()

    for (const file of Object.values(this.files)) {
      data.items.add(file as File)
    }

    this.inputTarget.files = data.files
  }

  /// ///////////////// START OF FOR DEMO ONLY ////////////////////
  // NOTE: remove this when the API and requirements are ready
  // NOTE: any code below this line is considered as dirty non-maintainable code
  smartScan (event: Event): void {
    const button = event.currentTarget as HTMLDivElement
    const textElement = button.querySelector('span') as HTMLDivElement
    const iconElement = button?.querySelector('svg') as SVGSVGElement
    const parentElementWithClass = button.closest("[data-file-removable-target='smartPreview']") as HTMLLIElement
    const form = button.closest('form') as any
    const file = this.files[parentElementWithClass.id]

    textElement.innerText = 'Processing...'
    iconElement.classList?.add('animate-pulse')

    this.sendApiRequest(file)
      .then(response => {
        textElement.innerText = 'Generating results...'

        setTimeout(() => {
          const previewElement = parentElementWithClass.querySelector('.hidden') as HTMLDivElement
          const img = document.createElement('img')

          this.setAiResult('race_pass_poster_available', form, response.result)
          this.setAiResult('maintenance_card', form, response.result)
          this.setAiResult('no_of_extinguisher', form, response.result)

          const aiResultBlock = form.querySelector('#ai_result') as HTMLInputElement
          aiResultBlock?.classList?.remove('hidden')

          this.smartSelectResponse(response.result, parentElementWithClass)

          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (response?.images && response.images[0]) {
            // Decode the base64 data
            const byteCharacters = atob(response.images[0])
            // Create a Uint8Array from the decoded data
            const byteArray = new Uint8Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
              byteArray[i] = byteCharacters.charCodeAt(i)
            }
            // Create a Blob from the Uint8Array
            const blob = new Blob([byteArray], { type: 'image/jpeg' })

            img.src = URL.createObjectURL(blob)
            parentElementWithClass.querySelector('#smart_preview')?.appendChild(img)

            button.remove()
            previewElement.classList.remove('hidden')

            this.add(new File([blob], 'smart_image.png', { type: 'image/jpg' }), undefined, true)
            this.updateInput()
          }
        }, 2000)
      })
      .catch(error => console.error(error))
  }

  private async sendApiRequest (file: File): Promise<any> {
    const url = 'https://pocfireapimgmt.azure-api.net/k8s/dohfm/fire'
    const formData = new FormData()

    formData.append('images', file)
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Ocp-Apim-Subscription-Key': '4ad21823b1c345c7bfe2e3c1816adc35'
        },
        body: formData
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      return await response.json()
    } catch (error) {
      console.log('Error sending request:', error)
      throw error
    }
  }

  private smartSelectResponse (options: ResultOptions, parentElementWithClass: any): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { race_pass_poster_available, maintenance_card } = options

    let radioIndex

    if (race_pass_poster_available.toString().toLowerCase() !== 'yes' &&
        maintenance_card.toString().toLowerCase() !== 'yes') {
      radioIndex = 2
    } else {
      radioIndex = 3
    }

    if (race_pass_poster_available.toString().toLowerCase() === 'yes' &&
        maintenance_card.toString().toLowerCase() === 'yes') {
      radioIndex = 0
    }

    const inputRegex = 'input[type="radio"][name="assessments_control[control_answer_id]"]'
    const radioElements = parentElementWithClass.closest('form').querySelectorAll(inputRegex)

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (radioElements[radioIndex]) {
      radioElements[radioIndex].checked = true
    }
  }

  private setAiResult (name: string, element: any, result: any): void {
    const control = element.querySelector(`#assessments_control_${name}`) as HTMLInputElement
    const div = element.querySelector(`#${name} span`) as HTMLDivElement

    div.innerText = result[name]
    control.value = result[name]
  }
  /// ///////////////// END OF FOR DEMO ONLY ////////////////////
}
