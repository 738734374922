export interface SelectOption {
  id: number | string
  name: string
}

export interface SelectGroup {
  group: string
  options: Options
}

export interface GroupOrOptions extends Array<SelectOption | SelectGroup> {}
export interface Options extends Array<SelectOption> {}

export default class DynamicSelect {
  element: HTMLSelectElement
  selectedValue: string

  constructor (element: HTMLSelectElement) {
    this.element = element
    this.selectedValue = element.value
  }

  reset (options: GroupOrOptions): void {
    this.select()
    this.clear()

    const htmlOptions = options.map(option => this.buildGroupOrOption(option))

    this.appendTo(htmlOptions, this.element)
  }

  select (): void {
    this.selectedValue = this.element.value
  }

  private clear (): void {
    while (this.element.firstChild != null) {
      this.element.removeChild(this.element.firstChild)
    }
  }

  private appendTo (options: Array<HTMLOptGroupElement | HTMLOptionElement>, container: HTMLSelectElement | HTMLOptGroupElement): void {
    options.forEach((option) => {
      container.appendChild(option)
    })
  }

  private buildGroupOrOption (option: SelectGroup | SelectOption): HTMLOptGroupElement | HTMLOptionElement {
    if ('group' in option) {
      return this.buildGroup(option)
    } else {
      return this.buildOption(option)
    }
  }

  private buildGroup ({ group, options }: SelectGroup): HTMLOptGroupElement {
    const optgroup = document.createElement('optgroup')
    const selectOptions = options.map(option => this.buildOption(option))
    optgroup.label = group
    this.appendTo(selectOptions, optgroup)

    return optgroup
  }

  private buildOption ({ id, name }: SelectOption): HTMLOptionElement {
    const option = document.createElement('option')

    option.value = `${id}`
    option.innerHTML = name || option.value // eslint-disable-line @typescript-eslint/strict-boolean-expressions
    option.selected = option.value === this.selectedValue

    return option
  }
}
