import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['button']
  static classes = ['disabled']
  static values = {
    disabled: { type: Boolean, default: true }
  }

  declare readonly buttonTarget: HTMLLinkElement
  declare readonly disabledClasses: string[]
  declare disabledValue: boolean

  disable (): void {
    this.disabledValue = true
  }

  private disabledValueChanged (): void {
    if (this.disabledValue) {
      this.buttonTarget.classList.add(...this.disabledClasses)
      this.element.dataset.controller = 'popover'
    }
  }
}
