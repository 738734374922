import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
  static values = {
    href: String
  }

  declare hrefValue: string
  declare readonly hasHrefValue: boolean

  visit (e: any): void {
    const { target } = e

    if (!this.hasHrefValue || target.dataset.clickableRowDisabled === 'true') {
      return
    }

    e.preventDefault()

    Turbo.visit(this.hrefValue)
  }
}
