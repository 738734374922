import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize (): void {
    // NOTE: we add timeout to make sure that form is submitted and slideover dialog is still opened
    setTimeout(() => {
      this.element.setAttribute('data-controller', 'alert')
      document.querySelectorAll('dialog[open] #dialog-flash').forEach((wrapper) => {
        this.element.setAttribute('data-controller', 'alert dialog-alert')
        wrapper.replaceChildren(this.element)
      })
    }, 300)
  }
}
