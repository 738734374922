import { GridStackNode } from 'gridstack'
import { railsRequest } from '../modules/helpers'

interface WidgetAttributes {
  id: number | string
  x: number | undefined
  y: number | undefined
}

export default class Dashboard {
  id: number

  constructor (id: number) {
    this.id = id
  }

  save (widgets: GridStackNode[]): void {
    const attributes: { [key: number]: WidgetAttributes } = {}

    widgets.forEach((widget: GridStackNode, index: number) => {
      const { id, x, y } = widget

      if (id !== undefined) {
        attributes[index] = { id, x, y }
      }
    })

    void railsRequest(`/dashboards/${this.id}`, {
      method: 'patch',
      data: { dashboard: { widgets_attributes: attributes } }
    })
  }
}
