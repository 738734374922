import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['existingOrganizationInput', 'organizationInput']

  declare existingOrganizationInputTarget: HTMLInputElement
  declare readonly organizationInputTargets: HTMLInputElement[]

  connect (): void {
    this.existingOrganizationOnChange()
  }

  existingOrganizationOnChange (): void {
    this.organizationInputTargets.forEach((input) => {
      this.toggleInputState(input, !this.existingOrganization())
    })
  }

  private existingOrganization (): boolean {
    switch (this.existingOrganizationInputTarget.value) {
      case 'true':
        return true
      default:
        return false
    }
  }

  private toggleInputState (input: HTMLInputElement, value: boolean): void {
    input.disabled = value

    if (value) {
      input.setAttribute('required', 'required')
      input.value = ''
    } else {
      input.removeAttribute('required')
    }
  }
}
