import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['listItem', 'showMore']

  declare readonly listItemTargets: HTMLElement[]
  declare readonly showMoreTarget: HTMLElement

  showMore (): void {
    this.listItemTargets.forEach(item => {
      item.classList.add('show')
    })
    this.showMoreTarget.classList.remove('show')
  }
}
