import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'form']

  declare readonly formTarget: HTMLFormElement
  declare readonly modalTarget: HTMLFormElement

  submit (event: any): void {
    if (!this.modalTarget.classList.contains('hidden')) {
      event.preventDefault()
      this.formTarget.requestSubmit()
    }
  }
}
