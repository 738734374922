import { Controller } from '@hotwired/stimulus'
import { Modal } from 'tailwindcss-stimulus-components'

export default class extends Controller {
  static outlets = ['modal']
  static values = { clearTurboFrame: Boolean }

  declare readonly modalOutlet: Modal
  declare readonly clearTurboFrameValue: boolean

  open (e: Event): void {
    const turboFrameSelector = this.element.getAttribute('data-turbo-frame')

    if (this.clearTurboFrameValue && turboFrameSelector !== null) {
      const turboFrame = this.modalOutlet.element.querySelector(`#${turboFrameSelector}`)

      if (turboFrame !== undefined) {
        turboFrame.innerHTML = ''
      }
    }

    this.modalOutlet.open(e)
    this.modalOutlet.context.scope.element.focus()
  }
}
