import { Controller } from '@hotwired/stimulus'
import { useMatchMedia } from 'stimulus-use'

const htmlElement = document.documentElement

export default class extends Controller {
  static values = {
    appearance: { type: String, default: 'system' }
  }

  declare appearanceValue: string

  isPrinting: boolean

  connect (): void {
    if (this.appearanceValue === 'system') {
      useMatchMedia(this, {
        mediaQueries: {
          dark: '(prefers-color-scheme: dark)'
        }
      })
    }

    window.addEventListener('beforeprint', () => {
      this.isPrinting = true
    })

    window.addEventListener('afterprint', () => {
      this.isPrinting = false
    })
  }

  isDark (): void {
    this.appearanceValue = 'dark'
  }

  notDark (): void {
    this.appearanceValue = 'light'
  }

  private appearanceValueChanged (): void {
    htmlElement.classList.remove('light', 'dark')
    htmlElement.classList.add(this.appearanceValue)
  }
}
