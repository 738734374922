import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
  static targets = ['checkbox', 'copyButton']

  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly copyButtonTarget?: HTMLButtonElement | undefined
  declare readonly hasCopyButtonTarget: boolean

  select (): void {
    const selectedCheckboxes = this.getSelectedCheckboxes()
    const isOneCheckboxSelected = selectedCheckboxes.length === 1

    if (this.copyButtonTarget != null) {
      this.copyButtonTarget.disabled = !isOneCheckboxSelected
    }
  }

  copy (): void {
    const selectedCheckboxes = this.getSelectedCheckboxes()
    const sourceProjectId = selectedCheckboxes[0].value

    this.visit(sourceProjectId)
  }

  reset (): void {
    this.visit(null)
  }

  private getSelectedCheckboxes (): HTMLInputElement[] {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  private visit (sourceProjectId: string | null): void {
    const params = new URLSearchParams(window.location.search)

    if (sourceProjectId != null) {
      params.set('source_project_id', sourceProjectId)
    } else {
      params.delete('source_project_id')
    }

    Turbo.visit(`${window.location.pathname}?${params.toString()}`)
  }
}
