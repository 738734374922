import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['valueType', 'optionsWrapper', 'options', 'sourceTypeInput']

  declare readonly valueTypeTarget: HTMLSelectElement
  declare readonly optionsWrapperTarget: HTMLDivElement
  declare readonly optionsTarget: HTMLElement
  declare readonly sourceTypeInputTarget: HTMLInputElement

  initialize (): void {
    this.changeValueType()
  }

  changeSourceType ({ currentTarget: { dataset: { sourceType } } }: { currentTarget: { dataset: { sourceType: string } } }): void {
    this.sourceTypeInputTarget.setAttribute('value', sourceType)
  }

  addOption (): void {
    setTimeout(() => {
      const option = this.optionsTarget.lastElementChild

      if (option != null) {
        option.dispatchEvent(new Event('fields-option:initialize'))
      }
    })
  }

  changeValueType (): void {
    if (this.valueTypeTarget.value.includes('field_option_id')) {
      this.optionsWrapperTarget.style.display = 'initial'
    } else {
      this.optionsWrapperTarget.style.display = 'none'
    }
  }
}
