import { Controller } from '@hotwired/stimulus'
import { GridStack, GridItemHTMLElement } from 'gridstack'
import { FormItemFormController } from './'

export default class extends Controller<GridItemHTMLElement> {
  static targets = ['x', 'y', 'parentNestableId', 'nameLabel', 'saved']
  static outlets = ['form-item-form']

  declare readonly xTarget: HTMLInputElement
  declare readonly yTarget: HTMLInputElement
  declare readonly nameLabelTarget: HTMLElement
  declare readonly savedTarget: HTMLDivElement
  declare readonly parentNestableIdTarget: HTMLInputElement
  declare readonly formItemFormOutlet: FormItemFormController
  declare readonly hasParentNestableIdTarget: boolean

  initialize (): void {
    setTimeout(() => {
      this.prepareFormItemForm()
    })
  }

  prepare ({ detail: { x, y, parentNestableId } }: { detail: { x: string, y: string, parentNestableId: string } }): void {
    this.parentNestableIdTarget.value = parentNestableId
    this.savePosition({ detail: { x, y } })
    this.prepareFormItemForm()
  }

  savePosition ({ detail: { x, y } }: { detail: { x: string, y: string } }): void {
    this.xTarget.value = x
    this.yTarget.value = y
  }

  updateHeight ({ detail: { h } }: { detail: { h: number } }): void {
    this.gridstack.update(this.element, { h })
  }

  save (name: string, width: number, params: string[][]): void {
    if (name.length > 0) {
      this.nameLabelTarget.innerText = name
    }

    this.gridstack.update(this.element, { w: width })
    this.appendInputs(params)
  }

  remove (): void {
    this.gridstack.removeWidget(this.element, false)
    this.dispatch('removed', { bubbles: false })
  }

  private appendInputs (params: string[][]): void {
    const htmlInputs: HTMLInputElement[] = []

    for (const [name, value] of params) {
      htmlInputs.push(this.buildInput(name, value))
    }

    this.savedTarget.replaceChildren(...htmlInputs)
  }

  private buildInput (name: string, value: string): HTMLInputElement {
    const input = document.createElement('input')

    input.setAttribute('type', 'hidden')
    input.setAttribute('name', name)
    input.setAttribute('value', value)

    return input
  }

  private prepareFormItemForm (): void {
    if (!this.hasParentNestableIdTarget) {
      return
    }

    this.formItemFormOutlet.prepare(this.parentNestableIdTarget.value.length > 0)
  }

  private get gridstack (): GridStack {
    // @ts-expect-error
    return this.element.gridstackNode?.grid
  }
}
