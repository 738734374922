import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'name',
    'values',
    'valuesWrapper'
  ]

  declare readonly nameTarget: HTMLSelectElement
  declare readonly valuesTarget: HTMLSelectElement
  declare readonly valuesWrapperTarget: HTMLDivElement

  initialize (): void {
    setTimeout(() => {
      this.nameChanged()
    })
  }

  nameChanged (): void {
    switch (this.nameTarget.value) {
      case 'project_business_process_transition':
        this.valuesTarget.disabled = false
        this.valuesWrapperTarget.classList.remove('hidden')
        break
      default:
        this.valuesTarget.disabled = true
        this.valuesWrapperTarget.classList.add('hidden')
    }
  }
}
