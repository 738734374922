import { Controller } from '@hotwired/stimulus'
import { GridStack, GridStackOptions, GridStackElement, GridStackNode, GridItemHTMLElement } from 'gridstack'

const gridstackOptions: GridStackOptions = {
  acceptWidgets: '.grid-stack-item.sub',
  animate: false,
  cellHeight: 90,
  column: 12,
  disableResize: true,
  float: false,
  itemClass: 'sub',
  margin: 6,
  minRow: 1
}

export default class extends Controller<GridItemHTMLElement> {
  static targets = ['container', 'nestableId']
  static values = {
    height: Number
  }

  declare readonly containerTarget: GridStackElement
  declare readonly nestableIdTarget: HTMLInputElement
  declare heightValue: number

  private gridstack: GridStack

  initialize (): void {
    this.gridstack = GridStack.init(gridstackOptions, this.containerTarget)

    this.gridstack.on('added', this.addCallback.bind(this))
    this.gridstack.on('removed', this.recalculateHeight.bind(this))
    this.gridstack.on('change', this.changeCallback.bind(this))

    this.recalculateHeight()
  }

  private heightValueChanged (): void {
    this.element.dispatchEvent(
      new CustomEvent('form-section:heightChanged', { detail: { h: this.heightValue } })
    )
  }

  private recalculateHeight (): void {
    this.heightValue = this.gridstack.getRow() + 1
  }

  private addCallback (_event: Event, nodes: GridStackNode[]): void {
    this.recalculateHeight()

    setTimeout(() => {
      nodes.forEach((node) => this.addFormItem(node))
    }, 0)
  }

  private changeCallback (_: Event, nodes: GridStackNode[]): void {
    this.recalculateHeight()

    if (nodes === undefined) {
      return
    }

    nodes.forEach((node) => this.savePosition(node))
  }

  private savePosition (node: GridStackNode): void {
    const { el } = node
    const event = new CustomEvent('item:move', { detail: node })

    if (el === undefined) {
      return
    }

    el.dispatchEvent(event)
  }

  private addFormItem (node: GridStackNode): void {
    const { el, x, y } = node

    if (el === undefined) {
      return
    }

    const event = new CustomEvent('item:added', { detail: { parentNestableId: this.nestableIdTarget.value, x, y } })

    el.dispatchEvent(event)
  }
}
