import { Controller } from '@hotwired/stimulus'

interface Chip {
  value?: string
  label?: string
}

export default class extends Controller {
  static targets = ['chips', 'chipTemplate', 'resetButton', 'checkbox', 'search']

  declare readonly chipsTarget: HTMLElement
  declare readonly chipTemplateTarget: HTMLTemplateElement
  declare readonly resetButtonTarget: HTMLButtonElement
  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly searchTarget: HTMLInputElement

  connect (): void {
    this.initializeFilters()
    this.updateVisibility()
  }

  toggle (event: Event): void {
    const checkbox = event.target as HTMLInputElement

    if (checkbox.checked) {
      this.createChip(checkbox)
    } else {
      this.deleteChip(checkbox, event)
    }

    this.submitForm(event)
  }

  remove (event: Event): void {
    const target = event.target as HTMLElement
    const chip = target.closest('[data-value][data-label]') as HTMLElement

    if (chip != null) {
      const { value, label } = chip.dataset as Chip

      if (value !== undefined && label !== undefined) {
        chip.remove()
        this.unselectCheckbox(value, label, event)
      }
    }
  }

  submitForm (event: Event): void {
    const form = this.element as HTMLFormElement

    event.preventDefault()
    form.requestSubmit()

    this.updateVisibility()
  }

  resetSearch (event: Event): void {
    this.searchTarget.value = ''
    this.submitForm(event)
  }

  resetFilters (event: Event): void {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
      this.deleteChip(checkbox, event)
    })

    this.submitForm(event)
  }

  private initializeFilters (): void {
    this.checkboxTargets.forEach(cb => {
      if (cb.checked) {
        this.createChip(cb)
      }
    })
  }

  private createChip (checkbox: HTMLInputElement): void {
    const label = checkbox.dataset.label

    if (label == null) return

    const chipClone = this.chipTemplateTarget.content.cloneNode(true) as DocumentFragment
    const chipElement = chipClone.querySelector('div')
    const chipLabel = chipClone.querySelector('span')

    if (chipElement != null && chipLabel != null) {
      chipElement.dataset.value = checkbox.value
      chipElement.dataset.label = label
      chipLabel.textContent = label
      this.chipsTarget.appendChild(chipClone)
    }
  }

  private deleteChip (checkbox: HTMLInputElement, event: Event): void {
    const chip = this.findChip(checkbox)

    if (chip != null) {
      chip.remove()
    }

    this.submitForm(event)
  }

  private findChip (checkbox: HTMLInputElement): HTMLElement | null {
    const label = checkbox.dataset.label ?? ''

    return label === ''
      ? null
      : this.chipsTarget.querySelector(`[data-value="${checkbox.value}"][data-label="${label}"]`)
  }

  private unselectCheckbox (value: string, label: string, event: Event): void {
    const checkbox = this.checkboxTargets.find(
      (cb) => cb.value === value && cb.dataset.label === label
    )

    if (checkbox != null) {
      checkbox.checked = false
      this.submitForm(event)
    }
  }

  private updateVisibility (): void {
    setTimeout(() => {
      const showChips = this.chipsTarget.children.length - 1 > 0

      this.resetButtonTarget.style.display = showChips ? 'block' : 'none'
      if (this.chipsTarget.parentElement != null) {
        this.chipsTarget.parentElement.style.display = showChips ? 'flex' : 'none'
      }
    }, 0)
  }
}
