import { Controller } from '@hotwired/stimulus'
import { GridItemHTMLElement } from 'gridstack'

export default class extends Controller<GridItemHTMLElement> {
  static targets = ['form', 'name', 'edit', 'position', 'saved', 'deleteForm']

  declare readonly formTarget: HTMLFormElement
  declare readonly nameTarget: HTMLElement
  declare readonly editTarget: HTMLButtonElement
  declare readonly positionTarget: HTMLInputElement
  declare readonly deleteFormTarget: HTMLFormElement

  openEdit ({ detail: { position } }: { detail: { position: string } }): void {
    // NOTE: we need to distinguish new questions from left sidebar and question that has been added to the grid
    this.formTarget.id = 'current_questionnaire_question_form'
    this.nameTarget.id = 'current_questionnaire_question_name'
    this.positionTarget.value = position
    setTimeout(() => this.editTarget.click())
  }

  formSaved (event: Event): void {
    setTimeout(() => {
      this.dispatch('saved')
    }, 300)
  }

  private savedTargetConnected (form: { dataset: { domId: string, id: string } }): void {
    this.element.id = form.dataset.domId
    this.deleteFormTarget.action += `/${form.dataset.id}`
    this.dispatch('saved', { detail: { id: form.dataset.id } })
  }
}
