import { Controller } from '@hotwired/stimulus'
import DynamicSelect, { Options } from '../../modules/dynamic_select'

export default class extends Controller {
  static targets = [
    'name',
    'valueSelect',
    'valueInput',
    'valueCheckbox',
    'fromSender',
    'recipientsList',
    'ccRecipientsList',
    'bccRecipientsList',
    'recipient',
    'thresholdScore',
    'domainThresholdScore',
    'invitationInput',
    'konnectifyIntegrationAppsInput',
    'konnectifyIntegrationKonnectorsInput',
    'fieldSelect',
    'fieldProjectSelect'
  ]

  static values = {
    nameOption: String,
    valueOption: String,
    values: Array,
    valueType: String
  }

  declare readonly nameTarget: HTMLInputElement
  declare readonly valueSelectTarget: HTMLSelectElement
  declare readonly valueInputTarget: HTMLInputElement
  declare readonly valueCheckboxTarget: HTMLInputElement
  declare readonly thresholdScoreTarget: HTMLInputElement
  declare readonly domainThresholdScoreTarget: HTMLInputElement
  declare readonly hasDomainThresholdScoreTarget: boolean
  declare readonly fromSenderTarget: HTMLDivElement
  declare readonly hasFromSenderTarget: boolean
  declare readonly recipientsListTarget: HTMLDivElement
  declare readonly ccRecipientsListTarget: HTMLDivElement
  declare readonly bccRecipientsListTarget: HTMLDivElement
  declare readonly recipientTargets: HTMLDivElement[]
  declare readonly invitationInputTargets: HTMLInputElement[]
  declare readonly konnectifyIntegrationAppsInputTarget: HTMLInputElement
  declare readonly hasKonnectifyIntegrationAppsInputTarget: boolean
  declare readonly konnectifyIntegrationKonnectorsInputTargets: HTMLInputElement[]
  declare readonly hasKonnectifyIntegrationKonnectorsInputTarget: boolean
  declare readonly fieldSelectTarget: HTMLInputElement
  declare readonly fieldProjectSelectTarget: HTMLInputElement
  declare nameOptionValue: string
  declare valueOptionValue: string

  declare valuesValue: any[]
  declare valueTypeValue: string
  declare initialState: boolean

  valueSelect: DynamicSelect
  recipientTypeOptions: Options

  initialize (): void {
    this.valueSelect = new DynamicSelect(this.valueSelectTarget)

    this.initialState = true
    setTimeout(() => { this.nameTarget.dispatchEvent(new Event('change')) })
  }

  updateOptions ({ detail: { options, recipientTypeOptions } }: { detail: { options: Options, recipientTypeOptions: Options } }): void {
    if (['set_facility_field', 'set_project_field'].includes(this.nameTarget.value)) {
      options = this.valuesValue
    }

    this.triggerChangeEventOnFieldSelect()

    this.valueSelect.reset(options)
    this.toggleValueTarget()

    this.showThresholdScore()
    this.showFacilityField()
    this.showProjectField()
    this.toggleRecipients(recipientTypeOptions)
    this.toggleEmailFields(recipientTypeOptions)
    this.toggleInvitationInputs()
    this.toggleKonnectifyIntegrationInputs()
  }

  updateValues ({ detail: { values, valueType } }: { detail: { values: any[], valueType: string } }): void {
    this.valuesValue = values
    this.valueTypeValue = valueType

    this.valueInputTarget.value = ''
    this.valueCheckboxTarget.checked = false
    this.valueSelect.reset(this.valuesValue)

    this.toggleValueTarget()
  }

  toggleValueTarget (): void {
    setTimeout(() => {
      const noValue = ['update_certification_status', 'set_scores', 'carry_forward', 'send_to_konnectify'].includes(this.nameTarget.value)
      const showSelect = this.valueSelectTarget.options.length > 0
      const showDateInput = this.valueSelectTarget.value === 'custom_date'

      if (showDateInput) { this.valueInputTarget.type = 'date' }

      this.toggle(this.valueSelectTarget, !noValue && showSelect)
      this.toggle(this.valueInputTarget, (!noValue && !showSelect && this.valueTypeValue !== 'boolean') || showDateInput)
      this.toggle(this.valueCheckboxTarget, !noValue && !showSelect && this.valueTypeValue === 'boolean')

      this.showWideSelect(['carry_forward', 'set_scores'].includes(this.nameTarget.value))
    })
  }

  loadRecipients ({ target }: { target: HTMLSelectElement }): void {
    const event = this.buildRecipientToggleEvent(true)

    target.dispatchEvent(event)
  }

  valueTypeValueChanged (): void {
    switch (this.valueTypeValue) {
      case 'float':
        this.valueInputTarget.type = 'number'
        break
      case 'datetime':
      case 'date':
        if (['yesterday', 'today', 'tomorrow'].includes(this.valueInputTarget.value)) {
          this.valueInputTarget.value = ''
        } else {
          this.valueInputTarget.type = 'date'
        }

        if (this.valueInputTarget.value.length === 0) {
          this.valueInputTarget.value = new Date().toISOString().slice(0, 10)
        }

        break
      default:
        this.valueInputTarget.type = 'text'
    }
  }

  private triggerChangeEventOnFieldSelect (): void {
    // we do not want to trigger change events in initial state
    if (this.initialState) {
      this.initialState = false
    } else {
      setTimeout(() => {
        if (this.nameTarget.value === 'set_facility_field') { this.fieldSelectTarget.dispatchEvent(new Event('change')) }
        if (this.nameTarget.value === 'set_project_field') { this.fieldProjectSelectTarget.dispatchEvent(new Event('change')) }
      })
    }
  }

  private toggle (input: HTMLInputElement | HTMLSelectElement, show: boolean): void {
    input.disabled = !show

    if (input === this.valueCheckboxTarget) {
      this.toggleHiddenClass(input.parentElement as HTMLInputElement, show)
    } else {
      this.toggleHiddenClass(input, show)
    }
  }

  private toggleHiddenClass (el: HTMLElement, show: boolean): void {
    el.classList.toggle('hidden', !show)
  }

  private showWideSelect (show: boolean): void {
    if (this.nameTarget.parentElement != null) {
      this.nameTarget.classList.toggle('h-full', show)
      this.nameTarget.parentElement.classList.toggle('h-full', show)
    }
  }

  private showThresholdScore (): void {
    const enable = this.nameTarget.value === 'update_certification_status'

    this.toggle(this.thresholdScoreTarget, enable)
    if (this.hasDomainThresholdScoreTarget) this.toggle(this.domainThresholdScoreTarget, enable)
  }

  private toggleRecipients (types: Options): void {
    this.recipientTypeOptions = types
    const actions = ['send_email_notification_template', 'send_sms_notification_template', 'send_invitation']
    const enable = actions.includes(this.nameTarget.value)
    const event = this.buildRecipientToggleEvent(enable)

    this.toggleHiddenClass(this.recipientsListTarget, enable)
    this.recipientTargets.forEach(target => target.dispatchEvent(event))
  }

  private toggleEmailFields (types: Options): void {
    this.recipientTypeOptions = types
    const actions = ['send_email_notification_template']
    const enable = actions.includes(this.nameTarget.value)

    this.toggleHiddenClass(this.ccRecipientsListTarget, enable)
    this.toggleHiddenClass(this.bccRecipientsListTarget, enable)

    if (this.hasFromSenderTarget && this.fromSenderTarget !== undefined) {
      this.toggleHiddenClass(this.fromSenderTarget, enable)
    }
  }

  private toggleInvitationInputs (): void {
    const enable = this.nameTarget.value === 'send_invitation'

    this.invitationInputTargets.forEach(input => this.toggle(input, enable))
  }

  private toggleKonnectifyIntegrationInputs (): void {
    const enable = this.nameTarget.value === 'send_to_konnectify'

    if (!this.hasKonnectifyIntegrationAppsInputTarget) return
    if (!this.hasKonnectifyIntegrationKonnectorsInputTarget) return

    this.toggle(this.konnectifyIntegrationAppsInputTarget, enable)
    this.konnectifyIntegrationKonnectorsInputTargets.forEach(input => {
      this.toggle(input, enable && this.konnectifyIntegrationAppsInputTarget.value === input.dataset.integrationAppId)
    })
  }

  private buildRecipientToggleEvent (enable: boolean): CustomEvent {
    return new CustomEvent('recipient:toggle', { detail: { enable, types: this.recipientTypeOptions } })
  }

  private showFacilityField (): void {
    const enable = this.nameTarget.value === 'set_facility_field'

    this.toggle(this.fieldSelectTarget, enable)
  }

  private showProjectField (): void {
    const enable = this.nameTarget.value === 'set_project_field'

    this.toggle(this.fieldProjectSelectTarget, enable)
  }
}
