import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']
  static values = { type: String }

  declare readonly contentTarget: HTMLElement
  declare readonly typeValue: string

  connect (): void {
    document.addEventListener('scroll', () => this.hide())
  }

  disconnect (): void {
    document.removeEventListener('scroll', () => this.hide())
  }

  show (): void {
    this.contentTarget.classList.remove('hidden')

    this.typeValue === 'sidebar' && this.sidebarPositionPopover()
  }

  hide (): void {
    this.contentTarget.classList.add('hidden')
  }

  private sidebarPositionPopover (): void {
    const triggerRect = this.element.getBoundingClientRect()
    const top = triggerRect.top + triggerRect.height

    this.contentTarget.style.top = `${top}px`
  }
}
