import { Controller } from '@hotwired/stimulus'
import { Options } from '../../modules/dynamic_select'

export default class extends Controller {
  static targets = [
    'eventName'
  ]

  static values = {
    users: Array,
    emailNotificationTemplates: Array,
    smsNotificationTemplates: Array,
    emailRecipientTypes: Array,
    smsRecipientTypes: Array,
    businessProcessStatuses: Array,
    reportTemplates: Array,
    dateFields: Array
  }

  declare usersValue: Options
  declare emailNotificationTemplatesValue: Options
  declare smsNotificationTemplatesValue: Options
  declare emailRecipientTypesValue: Options
  declare smsRecipientTypesValue: Options
  declare businessProcessStatusesValue: Options
  declare reportTemplatesValue: Options
  declare dateFieldsValue: Options

  updateActionValueOptions ({ target }: { target: HTMLSelectElement }): void {
    const event = new CustomEvent('action:updateOptions', {
      detail: {
        options: this.actionOptions(target.value),
        recipientTypeOptions: this.recipientTypeOptions(target.value)
      }
    })

    target.dispatchEvent(event)
  }

  private actionOptions (action: string): any[] {
    switch (action) {
      case 'send_email_notification_template':
        return this.emailNotificationTemplatesValue
      case 'send_sms_notification_template':
        return this.smsNotificationTemplatesValue
      case 'change_status':
        return this.businessProcessStatusesValue
      case 'generate_report_template':
        return this.reportTemplatesValue
      case 'send_invitation':
        return this.dateFieldsValue
      default:
        return []
    }
  }

  private recipientTypeOptions (action: string): any[] {
    switch (action) {
      case 'send_email_notification_template':
      case 'send_invitation':
        return this.emailRecipientTypesValue
      case 'send_sms_notification_template':
        return this.smsRecipientTypesValue
      default:
        return []
    }
  }
}
