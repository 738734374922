import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'
import { railsRequest } from '../modules/helpers'

export default class extends Controller {
  static targets = [
    'input', 'otpInput'
  ]

  static values = {
    url: { type: String, default: '/phone_verifications' }
  }

  declare urlValue: string
  declare readonly inputTarget: HTMLInputElement
  declare readonly otpInputTarget: HTMLInputElement

  connect (): void {
    document.addEventListener('phone-verification:success', () => {
      this.handleSuccess()
    })
  }

  handleSuccess (): void {
    setTimeout(() => {
      (this.element as HTMLFormElement).requestSubmit()
    }, 500)
  }

  handleFormSubmit (e: Event): void {
    if (this.otpInputTarget.value.length === 0) {
      e.preventDefault()
      this.openModal()
    }
  }

  openModal (): void {
    railsRequest(this.urlValue, {
      method: 'post',
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      },
      data: {
        phone_verification: {
          phone: this.inputTarget.value
        }
      }
    }).then((response) => {
      Turbo.renderStreamMessage(response.data)
    }).catch(error => console.error(error))
  }
}
