import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']
  static values = { source: String }

  declare readonly buttonTarget: HTMLButtonElement
  declare readonly hasSourceValue: boolean
  declare readonly sourceValue: string

  copy (e: Event): void {
    e.preventDefault()

    const text = this.hasSourceValue && this.sourceValue !== '' ? this.sourceValue.trim() : window.location.href

    navigator.clipboard.writeText(text).then(() => {
      this.feedbackAfterCopy()
    }).catch(err => {
      console.error('Failed to copy text: ', err)
    })
  }

  private feedbackAfterCopy (): void {
    const originalText = this.buttonTarget.textContent
    this.buttonTarget.textContent = 'Copied!'

    setTimeout(() => {
      this.buttonTarget.textContent = originalText
    }, 2000)
  }
}
