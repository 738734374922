import { Controller } from '@hotwired/stimulus'
import { DashboardController } from './'

export default class extends Controller {
  static outlets = ['dashboard']

  declare readonly dashboardOutlet: DashboardController
  declare readonly hasDashboardOutlet: boolean

  disconnect (): void {
    if (this.hasDashboardOutlet) {
      this.dashboardOutlet.remove(this.element)
    }
  }
}
