import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  declare readonly inputTargets: HTMLInputElement[]

  copy ({ target }: { target: HTMLInputElement }): void {
    this.inputTargets.forEach((input) => {
      input.value = target.value
    })
  }
}
