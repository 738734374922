import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = { visible: Boolean }

  declare readonly inputTarget: HTMLInputElement
  declare visibleValue: boolean

  toggle (): void {
    this.visibleValue = !this.visibleValue
  }

  private visibleValueChanged (): void {
    this.element.classList.toggle('is-visible', this.visibleValue)
    this.inputTarget.type = this.visibleValue ? 'text' : 'password'
  }
}
