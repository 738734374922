import { Controller } from '@hotwired/stimulus'

// NOTE: allows to add border to input
//   allows to clear input value
export default class extends Controller<HTMLInputElement> {
  touch (): void {
    this.element.classList.add('border-primary-hover')
  }

  reset (): void {
    this.element.classList.remove('border-primary-hover')

    this.clear()
  }

  clear (): void {
    this.element.value = ''
  }
}
