import { Controller } from '@hotwired/stimulus'
import { GridStack, GridStackOptions, GridStackElement, GridStackNode } from 'gridstack'

const gridstackOptions: GridStackOptions = {
  acceptWidgets: true,
  animate: false,
  cellHeight: 90,
  column: 12,
  disableResize: true,
  float: false,
  margin: 6
}

export default class extends Controller {
  static targets = ['container', 'item']

  declare readonly containerTarget: GridStackElement

  initialize (): void {
    GridStack.setupDragIn('.group.is-picker .grid-stack-item', { appendTo: 'body', helper: 'clone' })
  }

  connect (): void {
    const gridstack = GridStack.init(gridstackOptions, this.containerTarget)

    gridstack.on('change', this.changeCallback.bind(this))
    gridstack.on('added', this.addCallback.bind(this))
  }

  private changeCallback (_: Event, items: GridStackNode[]): void {
    if (items === undefined) {
      return
    }

    items.forEach((item) => this.savePosition(item))
  }

  private addCallback (_: Event, items: GridStackNode[]): void {
    setTimeout(() => {
      items.forEach((item) => this.addFormItem(item))
    }, 0)
  }

  private savePosition (item: GridStackNode): void {
    const { el } = item
    const event = new CustomEvent('item:move', { detail: item })

    if (el === undefined) {
      return
    }

    el.dispatchEvent(event)
  }

  private addFormItem (item: GridStackNode): void {
    const { el } = item

    if (el === undefined) {
      return
    }

    const event = new CustomEvent('item:added', { detail: { ...item, parentNestableId: '' } })

    el.dispatchEvent(event)
  }

  itemTargetConnected (item: HTMLElement): void {
    item.dispatchEvent(new CustomEvent('builder:dropped'))
  }
}
