import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/src/tom-select.popular'
import { TomInput, TomOption } from 'tom-select/src/types/index'
import checkboxOptions from 'tom-select/src/plugins/checkbox_options/plugin'
import removeButton from 'tom-select/src/plugins/remove_button/plugin'
import { escape_html } from 'tom-select/src/utils'

TomSelect.define('checkbox_options', checkboxOptions)
TomSelect.define('remove_button', removeButton)

interface MultiselectTomOption extends TomOption {
  text: string
  selectedTitle: string
}

export default class extends Controller {
  static targets = ['select', 'create']
  static values = {
    create: Boolean
  }

  declare createValue: boolean

  declare readonly hasSelectTarget: boolean
  declare readonly selectTarget: HTMLElement

  plugin: TomSelect

  connect (): void {
    let selectElement = this.element

    if (this.hasSelectTarget) {
      selectElement = this.selectTarget
    }

    this.plugin = new TomSelect(
      selectElement as TomInput, {
        plugins: ['checkbox_options', 'remove_button'],
        hidePlaceholder: true,
        create: this.createValue,
        render: {
          item: this.renderItem
        },
        onItemAdd: (value: string, item: HTMLElement) => this.dispatchItemChangeEvent(value, 'add'),
        onItemRemove: (value: string) => this.dispatchItemChangeEvent(value, 'remove'),
        onOptionAdd: (value: string) => this.dispatchItemChangeEvent(value, 'add-option')
      }
    )

    this.plugin.on('focus', () => setTimeout(() => this.selectDropdownPosition(), 0))
    this.plugin.on('type', () => this.selectDropdownPosition())
  }

  dispatchItemChangeEvent (value: string, action: string): void {
    this.dispatch(action, { bubbles: true, detail: { value } })
  }

  touch (element: Element): void {
    this.wrapperClassList().add('changed')
  }

  reset (): void {
    this.plugin.clear(true)
    this.wrapperClassList().remove('changed')
  }

  resetOptions ({ detail: { values } }: { detail: { values: any[] } }): void {
    const options: TomOption[] = values.map(value => ({ value: value.id, text: value.name }))

    this.plugin.clear(true)
    this.plugin.clearOptions()
    this.plugin.addOptions(options)
  }

  wrapperClassList (): DOMTokenList {
    return this.plugin.wrapper.classList
  }

  selectDropdownPosition (): void {
    setTimeout(() => {
      const dropdown = this.plugin.dropdown
      const inputRect = this.plugin.control_input.getBoundingClientRect()
      const spaceBelow = window.innerHeight - inputRect.bottom

      dropdown.style.top = (spaceBelow > 260) ? '' : `-${dropdown.clientHeight + 10}px`
    }, 0)
  }

  renderItem (data: MultiselectTomOption, escape: typeof escape_html): string {
    const text = data.selectedTitle !== undefined ? data.selectedTitle : data.text

    return `<div><p>${escape(text)}</p></div>`
  }
}
