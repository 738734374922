import { Controller } from '@hotwired/stimulus'
import { CurrentPageViewController } from '../page_views'

export default class extends Controller<HTMLFormElement> {
  static outlets = ['current-page-view']

  declare readonly currentPageViewOutlet: CurrentPageViewController

  submit (): void {
    this.addInputs()
  }

  private addInputs (): void {
    this.element.appendChild(this.buildInput('page_view_id', this.currentPageViewOutlet.pageViewId))
    this.currentPageViewOutlet.selectedColumns.forEach((column: string) => {
      this.element.appendChild(this.buildInput('sc[]', column)) // selected_columns short name (PageView::SELECTED_COLUMNS_PARAM)
    })
  }

  private buildInput (name: string, value: string): HTMLInputElement {
    const input = document.createElement('input')

    input.type = 'hidden'
    input.name = name
    input.value = value

    return input
  }
}
