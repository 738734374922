import axios from 'axios'

export async function railsRequest<T> (
  url: string,
  { headers = {}, data = {}, method = 'post' }
): Promise<any> {
  const defaultHeaders: { [key: string]: any } = { Accept: 'application/json' }

  const meta = document.querySelector('meta[name=csrf-token]')

  if (meta !== null) {
    defaultHeaders['X-CSRF-Token'] = meta.getAttribute('content')
  }

  return await axios<T>({
    url,
    method,
    data,
    headers: {
      ...defaultHeaders,
      ...headers
    }
  })
}
