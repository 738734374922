import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['note']
  static values = {
    existedNote: String,
    newNote: String
  }

  declare existedNoteValue: string
  declare newNoteValue: string
  declare noteTarget: HTMLElement

  onOptionSelected (event: any): void {
    this.noteTarget.textContent = this.isNumber(event.detail.value) ? this.existedNoteValue : this.newNoteValue
  }

  onOptionUnselected (event: any): void {
    this.noteTarget.textContent = ''
  }

  private isNumber (value: string): boolean {
    return (/^[1-9]\d*$/).test(value)
  }
}
