import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'formTemplate']
  static outlets = ['modals-container']

  declare readonly templateTarget: HTMLTemplateElement
  declare readonly formTemplateTarget: HTMLTemplateElement
  declare readonly modalsContainerOutletElement: HTMLDivElement

  selfReplace (): void {
    const index = new Date().getTime().toString()
    const form = this.formTemplateTarget.innerHTML.replace(/new_form_item/g, index)
    const parentElement = this.element.parentElement

    this.modalsContainerOutletElement.insertAdjacentHTML('beforeend', form)

    if (parentElement !== null) {
      parentElement.id = parentElement.id.replace(/new_form_item/g, index)
      parentElement.dataset.formItemFormItemFormOutlet = (parentElement.dataset.formItemFormItemFormOutlet ?? '').replace(/new_form_item/g, index)
    }

    this.element.outerHTML = this.templateTarget.innerHTML.replace(/new_form_item/g, index)
  }
}
