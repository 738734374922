import { Controller } from '@hotwired/stimulus'
import { GridStack, GridStackOptions, GridStackElement, GridStackNode } from 'gridstack'
import { Dashboard } from '../../models'

const gridstackOptions: GridStackOptions = {
  disableResize: true,
  animate: false,
  float: false,
  cellHeight: 106,
  column: 4,
  margin: 12
}

export default class extends Controller {
  static values = {
    id: Number,
    shared: Boolean,
    saveAfterInit: Boolean
  }

  declare idValue: number
  declare sharedValue: boolean
  declare saveAfterInitValue: boolean

  dashboard: Dashboard
  gridstack: GridStack

  initialize (): void {
    this.dashboard = new Dashboard(this.idValue)
    this.gridstack = GridStack.init(this.gridstackOptions, this.element as GridStackElement)
    this.gridstack.on('change', this.changeCallback.bind(this))

    if (this.saveAfterInitValue) {
      this.dashboard.save(this.gridstack.engine.nodes)
    }
  }

  remove (widget: Element): void {
    this.gridstack.removeWidget(widget as GridStackElement)
  }

  private changeCallback (_: Event, items: GridStackNode[]): void {
    if (items === undefined) {
      return
    }

    void this.dashboard.save(items)
  }

  private get gridstackOptions (): GridStackOptions {
    return { ...gridstackOptions, staticGrid: this.sharedValue }
  }
}
