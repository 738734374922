import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'

export default class extends Controller {
  static targets = ['fileInput', 'hiddenInput', 'spinner', 'errorMessage']

  declare readonly fileInputTarget: HTMLInputElement
  declare readonly hiddenInputTarget: HTMLInputElement
  declare readonly spinnerTarget: HTMLElement
  declare readonly errorMessageTarget: HTMLElement

  // Do not send file in form data, using signed_id in hidden field instead
  submit (): void {
    this.fileInputTarget.disabled = true
  }

  // For those which dont reload the form, eg. settings/users
  submitSuccess (): void {
    this.fileInputTarget.disabled = false
  }

  upload (): void {
    const file = this.fileInputTarget.files?.[0]
    const url = this.fileInputTarget.dataset.directUploadUrl

    if (file === null || file === undefined || url === null || url === undefined) {
      return
    }

    this.toggleDisabled(true)
    this.errorMessageTarget.classList.add('hidden')

    const directUpload = new DirectUpload(file, url, this)

    directUpload.create((error, blob) => {
      this.spinnerTarget.classList.add('hidden')
      this.toggleDisabled(false)
      this.hiddenInputTarget.disabled = false

      if (error !== null) {
        this.errorMessageTarget.classList.remove('hidden')
        this.fileInputTarget.value = ''
      } else {
        this.hiddenInputTarget.value = blob.signed_id
        this.dispatch('uploaded')
      }
    })
  }

  // Called by activestorage.js
  directUploadWillCreateBlobWithXHR (_request: XMLHttpRequest): void {
    this.spinnerTarget.classList.remove('hidden')
  }

  private toggleDisabled (disabled: boolean): void {
    this.fileInputTarget.disabled = disabled
    this.fileInputTarget.closest(`label[for=${this.fileInputTarget.id}]`)?.classList.toggle('disabled', disabled)
  }
}
