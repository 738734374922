import { Controller } from '@hotwired/stimulus'
import SearchController from './search_controller'

export default class extends Controller {
  static targets = ['input', 'icon', 'toggle']
  static outlets = ['search']

  declare readonly inputTarget: HTMLInputElement
  declare readonly iconTarget: HTMLDivElement
  declare readonly toggleTarget: HTMLDivElement
  declare readonly searchOutlet: SearchController

  connect (): void {
    this.closeAdvancedSearch()
  }

  toggle (): void {
    this.inputTarget.value = String(this.inputTarget.value !== 'true')
    this.iconTarget.classList.toggle('rotate-180')
  }

  closeAdvancedSearch (): void {
    const length = this.searchOutlet.groupingsTarget.querySelectorAll('input:not(.hidden), select:not(.hidden)').length

    if (length === 0 && this.inputTarget.value === 'true') {
      this.toggleTarget.click()
    }
  }
}
