import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
  static targets = ['form']

  declare readonly formTarget: HTMLFormElement

  connect (): void {
    this.formTarget.addEventListener('reset', this.resetForm.bind(this))
  }

  disconnect (): void {
    this.formTarget.removeEventListener('reset', this.resetForm.bind(this))
  }

  submitForm (event: Event): void {
    event.preventDefault()

    const form = this.formTarget
    const formData = new FormData(form)

    this.visit((params: URLSearchParams): URLSearchParams => {
      for (const [key, value] of formData.entries()) {
        const stringValue = String(value)

        if (stringValue == null || stringValue === '') {
          params.delete(key)
        } else if (params.has(key)) {
          const existingValues = params.getAll(key)

          if (!existingValues.includes(stringValue)) {
            params.append(key, stringValue)
          }
        } else {
          params.set(key, stringValue)
        }
      }

      // NOTE: reset the page to 1
      params.delete('page')

      return params
    })
  }

  resetForm (): void {
    this.clearAllFields()

    this.visit((params: URLSearchParams): URLSearchParams => {
      const formFieldNames = Array.from(this.formTarget.elements).map(element => (element as HTMLInputElement).name).filter(name => name)

      formFieldNames.forEach(fieldName => {
        params.delete(fieldName)
      })

      return params
    })

    const event = new CustomEvent('count-checkboxes:update')
    document.dispatchEvent(event)
  }

  private visit (cb: Function): void {
    const currentSearchParams = new URLSearchParams(window.location.search)
    const params = cb(currentSearchParams)
    const url = `${window.location.pathname}?${params as string}${window.location.hash}`

    history.pushState(null, '', url)

    Turbo.visit(url, { frame: this.formTarget.dataset.turboFrame })
  }

  private clearAllFields (): void {
    Array.from(this.formTarget.elements).forEach(element => {
      const input = (element as HTMLInputElement)

      switch (input.type) {
        case 'search':
          input.value = ''
          break
        case 'checkbox':
          input.checked = false
          break
      }
    })
  }
}
