import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['sidebar', 'content']
  static values = {
    collapsed: Boolean
  }

  declare collapsedValue: boolean
  declare readonly sidebarTarget: HTMLElement
  declare readonly contentTarget: HTMLElement

  connect (): void {
    this.contentTarget.classList.add('no-animation')
    this.sidebarTarget.classList.add('no-animation')

    const sidebarAndChildren = this.sidebarTarget.querySelectorAll('.sidebar, .sidebar *')
    sidebarAndChildren.forEach((element) => {
      element.classList.add('no-animation')
    })
    this.loadSidebarState()

    setTimeout(() => {
      this.removeNoAnimationClasses()
    }, 500)
  }

  toggle (): void {
    this.collapsedValue = !this.collapsedValue
    this.saveSidebarState()
  }

  collapsedValueChanged (): void {
    if (this.collapsedValue) {
      this.sidebarTarget.classList.add('collapsed')
      this.sidebarTarget.classList.remove('full')
      this.contentTarget.classList.add('sidebar-collapsed')
      this.contentTarget.classList.remove('sidebar-full')
    } else {
      this.sidebarTarget.classList.add('full')
      this.sidebarTarget.classList.remove('collapsed')
      this.contentTarget.classList.add('sidebar-full')
      this.contentTarget.classList.remove('sidebar-collapsed')
    }
  }

  saveSidebarState (): void {
    if (!window.location.pathname.includes('/settings')) {
      localStorage.setItem('sidebarState', JSON.stringify(this.collapsedValue))
    }
  }

  loadSidebarState (): void {
    const savedState = localStorage.getItem('sidebarState')

    if (window.location.pathname.includes('/settings')) {
      this.collapsedValue = true
    } else if (savedState !== null) {
      this.collapsedValue = JSON.parse(savedState)
    }
  }

  removeNoAnimationClasses (): void {
    this.contentTarget.classList.remove('no-animation')
    this.sidebarTarget.classList.remove('no-animation')

    const sidebarAndChildren = this.sidebarTarget.querySelectorAll('.sidebar, .sidebar *')
    sidebarAndChildren.forEach((element) => {
      element.classList.remove('no-animation')
    })
  }
}
