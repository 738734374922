import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['email', 'sms', 'notificationType', 'emailOnlyPlaceholder']

  declare readonly emailTarget: HTMLInputElement
  declare readonly smsTarget: HTMLInputElement
  declare readonly notificationTypeTargets: HTMLInputElement[]

  initialize (): void {
    const target = this.notificationTypeTargets.find((input) => input.checked)

    if (target != null) {
      this.changeType({ target })
    }
  }

  changeType ({ target }: { target: HTMLInputElement }): void {
    if (target.value === 'sms') {
      this.hide(this.emailTarget)
      this.show(this.smsTarget)
    } else {
      this.hide(this.smsTarget)
      this.show(this.emailTarget)
    }
  }

  dragPlaceholder (event: any): void {
    event.dataTransfer.setData('text', event.params.placeholder)
  }

  dropOnTrixEditor (event: any): void {
    event.currentTarget.editor.insertString(event.dataTransfer.getData('text'))
  }

  removeTrixRequiredValidation (): void {
    const input = this.emailTarget.querySelector('input.trix-input.trix-input--dialog[required]')
    if (input !== null) {
      input.removeAttribute('required')
    }
  }

  private readonly show = (target: HTMLInputElement): void => {
    target.classList.remove('hidden')
    target.querySelectorAll('input, textarea').forEach((input: any) => {
      input.disabled = false
    })
  }

  private readonly hide = (target: HTMLInputElement): void => {
    target.classList.add('hidden')
    target.querySelectorAll('input, textarea').forEach((input: any) => {
      input.disabled = true
    })
  }
}
