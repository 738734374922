import { Controller } from '@hotwired/stimulus'
import WorkflowsActionGroupController from './action_group_controller'

export default class extends Controller {
  static targets = [
    'container',
    'advancedMode',
    'simpleMode'
  ]

  static outlets = ['workflows-action-group']

  static values = {
    advancedMode: Boolean,
    id: String
  }

  declare readonly containerTarget: HTMLDivElement
  declare readonly advancedModeTarget: HTMLDivElement
  declare readonly simpleModeTarget: HTMLDivElement
  declare readonly workflowsActionGroupOutlets: WorkflowsActionGroupController[]
  declare readonly workflowsActionGroupOutletElements: HTMLDivElement[]
  declare advancedModeValue: boolean
  declare idValue: string

  toggleMode (): void {
    this.advancedModeValue = !this.advancedModeValue
  }

  advancedModeValueChanged (): void {
    if (this.advancedModeValue) {
      this.simpleModeTarget.classList.add('hidden')
      this.advancedModeTarget.classList.remove('hidden')
      this.disableSimpleMode()
    } else {
      this.advancedModeTarget.classList.add('hidden')
      this.simpleModeTarget.classList.remove('hidden')
      this.enableSimpleMode()
    }
  }

  private enableSimpleMode (): void {
    setTimeout(() => {
      const actionGroup = this.workflowsActionGroupOutlets.find(mode => !mode.removedValue)

      if (actionGroup != null) {
        this.appendChildren(this.containerTarget, actionGroup.containerTarget)

        this.disableWorkflowsActionGroups()
      }
    })
  }

  private disableSimpleMode (): void {
    this.addNewGroupToAdvancedMode()
    this.moveActionsToAdvancedMode()
  }

  private addNewGroupToAdvancedMode (): void {
    const event = new CustomEvent('templatable:add', {
      detail: {
        id: this.idValue
      }
    })

    this.advancedModeTarget.dispatchEvent(event)
  }

  private moveActionsToAdvancedMode (): void {
    setTimeout(() => {
      const actionGroup = this.workflowsActionGroupOutlets[this.workflowsActionGroupOutlets.length - 1]

      this.appendChildren(actionGroup.containerTarget, this.containerTarget)
    })
  }

  private disableWorkflowsActionGroups (): void {
    const event = new Event('removable:remove')

    this.workflowsActionGroupOutletElements.forEach((removable: HTMLDivElement) => {
      removable.dispatchEvent(event)
    })
  }

  private appendChildren (container: HTMLDivElement, { children }: { children: HTMLCollection }): void {
    while (children.length > 0) {
      container.appendChild(children[0])
    }
  }
}
