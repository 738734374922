import { Controller } from '@hotwired/stimulus'

// this controller is used to count the number of checkboxes that are checked
// and display it in the target span element
export default class extends Controller {
  static targets = ['counter', 'checkbox']
  static values = {
    count: Number
  }

  declare readonly counterTarget: HTMLElement
  declare readonly checkboxTargets: HTMLInputElement[]
  declare countValue: number

  initialize (): void {
    this.countChecked = this.countChecked.bind(this)
  }

  connect (): void {
    document.addEventListener('count-checkboxes:update', this.countChecked)
    document.addEventListener('popstate', this.countChecked)

    this.countChecked()
  }

  disconnect (): void {
    document.removeEventListener('count-checkboxes:update', this.countChecked)
    document.removeEventListener('popstate', this.countChecked)
  }

  select ({ target }: { target: HTMLInputElement }): void {
    target.checked ? this.countValue++ : this.countValue--
  }

  private countValueChanged (): void {
    this.counterTarget.classList.toggle('hidden', this.countValue === 0)

    this.counterTarget.innerText = this.countValue.toString()
  }

  private countChecked (): void {
    this.countValue = this.checkboxTargets.filter((checkbox: HTMLInputElement) => checkbox.checked).length
  }
}
