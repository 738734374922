import { Controller } from '@hotwired/stimulus'

const defaultStatusFilter = 'all'

export default class extends Controller {
  static targets = ['rolesCount', 'facilitiesCount', 'roles', 'facilities', 'resetButton']

  static values = {
    rolesFilter: Array,
    facilitiesFilter: Array,
    statusFilter: String
  }

  declare readonly rolesCountTarget: HTMLElement
  declare readonly rolesTargets: HTMLInputElement[]
  declare readonly facilitiesCountTarget: HTMLElement
  declare readonly hasFacilitiesCountTarget: boolean
  declare readonly facilitiesTargets: string[]
  declare readonly resetButtonTarget: HTMLElement
  declare rolesFilterValue: string[]
  declare facilitiesFilterValue: string[]
  declare statusFilterValue: string

  rolesFilter (): void {
    this.rolesFilterValue = this.rolesTargets
      .filter((role) => role.checked)
      .map((role) => role.value)
  }

  facilitiesFilter (): void {
    this.facilitiesFilterValue = this.facilitiesTargets
  }

  statusFilter ({ params: { statusFilter } }: { params: { statusFilter: string } }): void {
    this.statusFilterValue = statusFilter
  }

  private rolesFilterValueChanged (): void {
    this.rolesCountTarget.textContent = this.rolesFilterValue.length.toString()
    this.updateButtonState()
  }

  private facilitiesFilterValueChanged (): void {
    if (this.hasFacilitiesCountTarget) {
      this.facilitiesCountTarget.textContent = this.facilitiesFilterValue.length.toString()
      this.updateButtonState()
    }
  }

  private statusFilterValueChanged (): void {
    this.updateButtonState()
  }

  private updateButtonState (): void {
    const hideResetButton =
      this.statusFilterValue === defaultStatusFilter &&
      this.rolesFilterValue.length === 0 &&
      this.facilitiesFilterValue.length === 0

    this.resetButtonTarget.classList.toggle('hidden', hideResetButton)
  }
}
