import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['destroyInput', 'discardInput']

  static values = {
    persisted: Boolean
  }

  declare readonly destroyInputTarget: HTMLInputElement
  declare readonly discardInputTarget: HTMLInputElement
  declare readonly hasDiscardInputTarget: boolean
  declare readonly hasDestroyInputTarget: boolean
  declare persistedValue: boolean

  remove (e: Event): void {
    e.preventDefault()

    this.removeRequired()
    this.destroy()
    this.hide()
  }

  private destroy (): void {
    if (this.hasDestroyInputTarget) {
      this.destroyInputTarget.value = 'true'
    }
    if (this.hasDiscardInputTarget) {
      this.discardInputTarget.value = new Date().toISOString()
    }
  }

  private removeRequired (): void {
    this.element.querySelectorAll('[required]').forEach((input: Element) => {
      input.removeAttribute('required')
    })
  }

  private hide (): void {
    if (this.persistedValue) {
      this.element.classList.add('hidden')
    } else {
      this.element.remove()
    }

    this.dispatch('removed')
    document.dispatchEvent(new Event('statusChanged'))
  }
}
