import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['name', 'nameWrapper']
  static values = {
    selected: Boolean
  }

  declare readonly nameTarget: HTMLInputElement
  declare readonly nameWrapperTarget: HTMLDivElement
  declare selectedValue: boolean

  selectType ({ target }: { target: HTMLSelectElement }): void {
    this.nameTarget.value = (target[target.selectedIndex] as HTMLOptionElement).text
    this.selectedValue = target.value.length > 1
  }

  selectedValueChanged (): void {
    const event = new Event('toggle:toggle')

    this.element.dispatchEvent(event)
  }
}
