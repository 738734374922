import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['groupings', 'addConditionGroup', 'addCondition', 'page']

  static values = {
    advancedSearch: Boolean
  }

  declare readonly groupingsTarget: HTMLDivElement
  declare readonly addConditionGroupTarget: HTMLButtonElement
  declare readonly addConditionTarget: HTMLButtonElement
  declare readonly pageTarget: HTMLInputElement
  declare readonly hasAddConditionTarget: boolean
  declare advancedSearchValue: boolean

  toggle (): void {
    this.advancedSearchValue = !this.advancedSearchValue

    // FIXME: refactor this
    //   unexpected bugs can happen here because we disable condition inputs both here and in condition_controller
    this.groupingsTarget
      .querySelectorAll<HTMLInputElement | HTMLSelectElement>('input:not(.hidden), select:not(.hidden)')
      .forEach((input: HTMLInputElement | HTMLSelectElement) => { input.disabled = !this.advancedSearchValue })

    if (this.advancedSearchValue && !this.hasAddConditionTarget) {
      this.addConditionGroupTarget.click()
      setTimeout(() => {
        this.addConditionTarget.click()
      })
    }
  }

  togglePage (): void {
    this.pageTarget.disabled = false

    setTimeout(() => {
      this.pageTarget.disabled = true
    })
  }
}
