import { Controller } from '@hotwired/stimulus'
import { Slideover } from 'tailwindcss-stimulus-components'

export default class extends Controller {
  static outlets = ['slideover']

  declare readonly slideoverOutlet: Slideover

  open (e: Event): void {
    this.slideoverOutlet.show()
    this.slideoverOutlet.context.scope.element.focus()
  }
}
