import { Controller } from '@hotwired/stimulus'
import toggleDisableController from '../toggle_disable_controller'

export default class extends Controller {
  static targets = ['checkbox']
  static outlets = ['toggle-disable']

  declare checkboxTarget: HTMLInputElement
  declare readonly toggleDisableOutlet: toggleDisableController

  setCheckboxChecked (): void {
    this.enableSignInButton()
    this.checkboxTarget.checked = true
  }

  private enableSignInButton (): void {
    if (!this.checkboxTarget.checked) {
      this.toggleDisableOutlet.toggle()
    }
  }
}
