import { Controller } from '@hotwired/stimulus'

// NOTE: this controller prevents sending input data if it's not touched
//   instead it sends input data under hidden param
//   we need such behaviour in bulk form to prevent update selected tickets
//   when user wants to change only part of ticket data
export default class extends Controller<HTMLInputElement> {
  static targets = ['input', 'checkbox']

  declare readonly inputTarget: HTMLInputElement
  declare readonly checkboxTarget: HTMLInputElement
  declare readonly hasInputTarget: boolean
  declare readonly hasCheckboxTarget: boolean

  input: HTMLInputElement
  inputName: string

  initialize (): void {
    this.input = this.element

    if (this.hasInputTarget) {
      this.input = this.inputTarget
    }

    this.inputName = this.input.name
    this.reset()
  }

  touch (): void {
    this.show()
  }

  reset (): void {
    this.hide()
  }

  show (): void {
    this.input.name = this.inputName

    if (this.hasCheckboxTarget) {
      this.checkboxTarget.checked = true
    }
  }

  hide (): void {
    this.input.name = 'hidden'

    if (this.hasCheckboxTarget) {
      this.checkboxTarget.checked = false
    }
  }
}
