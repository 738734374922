import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  declare readonly inputTarget: HTMLInputElement

  focus (): void {
    setTimeout(() => {
      this.inputTarget.focus()
    }, 100)
  }
}
