import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'banner']
  static values = {
    duration: Number
  }

  declare readonly formTarget: HTMLFormElement
  declare readonly bannerTarget: HTMLDivElement
  declare durationValue: number

  timeoutId: ReturnType<typeof setTimeout>
  private readonly warnBeforeSeconds = 45

  initialize (): void {
    this.submit = this.submit.bind(this)
    this.showBanner = this.showBanner.bind(this)
    this.heartbeat = this.heartbeat.bind(this)
    this.reset = this.reset.bind(this)
  }

  connect (): void {
    this.heartbeat()

    document.addEventListener('turbo:before-fetch-response', this.reset)
  }

  disconnect (): void {
    clearTimeout(this.timeoutId)

    document.removeEventListener('turbo:before-fetch-response', this.reset)
  }

  submit (): void {
    // do heartbeat request to keep session alive,
    // sessionUpdatedAt will be updated through turbo:before-fetch-response
    this.formTarget.requestSubmit()
  }

  close (): void {
    this.closeDialog()
  }

  private reset (): void {
    this.closeDialog()
    clearTimeout(this.timeoutId)
    this.heartbeat()
  }

  private showBanner (): void {
    const timeLeft = this.durationValue - Math.floor((Date.now() - this.loadSessionUpdatedAt()) / 1000)

    if (timeLeft > this.warnBeforeSeconds) {
      this.closeDialog() // to hide the banner if session was restored in another tab

      this.timeoutId = setTimeout(this.showBanner, (timeLeft - this.warnBeforeSeconds) * 1000)
      return
    }

    this.timeoutId = setTimeout(this.showBanner, 10 * 1000) // to hide the banner if session was restored in another tab

    this.openDialog()
  }

  private heartbeat (): void {
    this.saveSessionUpdatedAt()
    this.timeoutId = setTimeout(this.showBanner, (this.durationValue - this.warnBeforeSeconds) * 1000)
  }

  private saveSessionUpdatedAt (): void {
    localStorage.setItem('sessionUpdatedAt', Date.now().toString())
  }

  private loadSessionUpdatedAt (): number {
    return parseInt(localStorage.getItem('sessionUpdatedAt') ?? '0')
  }

  private openDialog (): void {
    const dialog = this.element as HTMLDialogElement

    if (dialog.hasAttribute('open')) return

    dialog.showModal()
  }

  private closeDialog (): void {
    const dialog = this.element as HTMLDialogElement

    if (!dialog.hasAttribute('open')) return

    dialog.close()
  }
}
