import { Controller } from '@hotwired/stimulus'
import { FormItemController } from './'

export default class extends Controller<HTMLFormElement> {
  static targets = ['name', 'width', 'permissions', 'parentPermissions']
  static outlets = ['form-item']

  declare readonly nameTarget: HTMLInputElement
  declare readonly widthTargets: HTMLInputElement[]
  declare readonly formItemOutlet: FormItemController
  declare readonly permissionsTarget: HTMLDivElement
  declare readonly parentPermissionsTarget: HTMLDivElement

  inputs: string[][]
  formHtml: string

  initialize (): void {
    // HACK: save the state only after all controllers / plugins have been initialized
    setTimeout(() => {
      this.save()
    }, 0)
  }

  prepare (hasParentNestableId: boolean): void {
    if (hasParentNestableId) {
      this.permissionsTarget.classList.add('hidden')
      this.parentPermissionsTarget.classList.remove('hidden')
    } else {
      this.parentPermissionsTarget.classList.add('hidden')
      this.permissionsTarget.classList.remove('hidden')
    }
  }

  save (): void {
    this.saveState()
    this.updateFormItem()
  }

  cancel (): void {
    this.element.innerHTML = this.formHtml

    for (const [name, value] of this.inputs) {
      if (name === 'visibility') {
        const radio = this.element.querySelector(`[name='${name}'][value='${value}']`)

        if (radio !== null) {
          (radio as HTMLInputElement).checked = true
        }
      } else if (name.includes('[write_role_ids][]') || name.includes('[read_role_ids][]')) {
        const option = this.element.querySelector(`select[name='${name}'] option[value='${value}']`)

        if (option !== null) {
          (option as HTMLOptionElement).selected = true
        }
      } else {
        const input = this.element.querySelector(`[name='${name}']`)

        if (input !== null) {
          (input as HTMLInputElement).value = value
        }
      }
    }

    if (this.nameTarget.value.length === 0) {
      this.formItemOutlet.remove()
    }

    // HACK: these hacks enable Tomselect multiselect in the slideover
    setTimeout(() => {
      this.element.querySelector('.ts-wrapper:last-of-type')?.remove()
      this.element.querySelector('.ts-wrapper.ts-hidden-accessible')?.classList.remove('ts-hidden-accessible')
    }, 0)
  }

  private saveState (): void {
    const formData = new FormData(this.element)

    this.formHtml = this.element.innerHTML
    this.inputs = []

    for (const [name, value] of formData.entries()) {
      this.inputs.push([name, (value as string).toString()])
    }
  }

  private updateFormItem (): void {
    const name = this.nameTarget.value
    const width = this.widthTargets.find((radio: HTMLInputElement) => radio.checked)?.value ?? '12'

    this.formItemOutlet.save(name, parseInt(width), this.inputs)
  }
}
