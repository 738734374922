import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'select', 'dropdown']

  declare readonly formTarget: HTMLFormElement
  declare readonly inputTarget: HTMLInputElement
  declare readonly selectTarget: HTMLSelectElement
  declare readonly dropdownTarget: HTMLElement

  submit (event: any): void {
    event.preventDefault()
    this.formTarget.requestSubmit()
  }

  input_and_submit (event: any): void {
    const selectedValue = this.selectTarget.value

    if (this.inputTarget != null) {
      this.inputTarget.value = selectedValue
      this.submit(event)
    }
  }

  hideDropdown (): void {
    this.dropdownTarget.classList.add('hidden')
  }
}
