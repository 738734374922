import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    selector: String
  }

  declare selectorValue: string

  registerEvent (event: any): void {
    event.preventDefault()

    const toggleEvent = new Event('visibility-toggle')

    const selectedElements = document.querySelectorAll(this.selectorValue)
    selectedElements.forEach(element => {
      element.dispatchEvent(toggleEvent)
    })
  }
}
