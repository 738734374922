import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output', 'input', 'validationText', 'removeButton']

  declare readonly inputTarget: HTMLInputElement
  declare readonly outputTarget: HTMLInputElement
  declare readonly removeButtonTarget: HTMLButtonElement
  declare readonly validationTextTarget: HTMLInputElement

  connect (): void {
    this.toggleRemoveButton()
  }

  readURL (): void {
    const file = this.inputTarget.files?.[0]
    const reader = new FileReader()

    if (!this.validate(file)) {
      return
    }

    this.outputTarget.hidden = false

    if (file === null || file === undefined) {
      return
    }

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        this.outputTarget.src = reader.result
        this.toggleRemoveButton(false)
      }
    }

    reader.readAsDataURL(file)
  }

  toggleRemoveButton (disable?: boolean): void {
    const placeholder = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

    if (disable === undefined) {
      this.toggleRemoveButton(this.outputTarget.src === placeholder)
    } else {
      this.removeButtonTarget.classList.toggle('disabled', disable)
    }
  }

  hideOutput (): void {
    this.toggleRemoveButton(true)
    this.outputTarget.hidden = true
    this.inputTarget.value = ''
  }

  validate (file: File | undefined): boolean {
    const validationText = this.validationTextTarget

    if (file == null) {
      return false
    }

    // Check file size (max 2MB)
    const maxSizeMB = 2
    const maxSize = maxSizeMB * 1024 * 1024
    if (file.size > maxSize) {
      validationText.classList.add('text-red-500')
      return false
    }

    // Check file type (must be JPEG or PNG)
    const fileType = file.type
    if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
      validationText.classList.add('text-red-500')
      return false
    }

    validationText.classList.remove('text-red-500')
    return true
  }
}
