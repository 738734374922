import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  declare readonly inputTarget: HTMLInputElement

  addImage (): void {
    this.inputTarget.disabled = true
    this.inputTarget.value = ''
  }

  removeImage (): void {
    this.inputTarget.disabled = false
    this.inputTarget.value = ''
  }
}
