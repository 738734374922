import SelectableController from './selectable_controller'
import { escape_html } from 'tom-select/src/utils'
import { TomOption } from 'tom-select/src/types'

const openCompanyIcon = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" class="h-4 w-4 text-primary dark:text-primary-dark shrink-0">' +
  '<path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path>' +
  '</svg>'

interface CompanyTomOption extends TomOption {
  name: string
  url: string
}

export default class extends SelectableController {
  static values = {
    url: { type: String, default: '/companies' }
  }

  settings = {
    plugins: ['virtual_scroll']
  }

  buildUrl (query: string): string {
    const joinOperator = this.urlValue.includes('?') ? '&' : '?'

    return `${this.urlValue}${joinOperator}page=${this.currentPage}` +
      (query === '' ? '' : `&q[name_cont]=${encodeURIComponent(query)}`)
  }

  renderOption (data: CompanyTomOption, escape: typeof escape_html): HTMLElement {
    const optionElement = document.createElement('div')
    optionElement.innerHTML = `<div class="w-full"><div class="flex items-center space-x-1.5"><p>${escape(data.name)}</p>` +
      `<a href="${escape(data.url)}" target="_blank">${openCompanyIcon}</a></div></div>`

    const companyAnchor = optionElement.querySelector('a') as HTMLAnchorElement
    companyAnchor.addEventListener('click', (event) => {
      event.stopPropagation()
    })

    return optionElement
  }

  renderItem (data: CompanyTomOption, escape: typeof escape_html): HTMLElement {
    const optionElement = document.createElement('div')
    optionElement.innerHTML = '<div class="flex items-center w-full">' +
      `<a class="h-4 w-4" href="${escape(data.url)}" target="_blank">${openCompanyIcon}</a>` +
      `<p class="px-1">${escape(data.name)}</p></div>`

    const companyAnchor = optionElement.querySelector('a') as HTMLAnchorElement
    companyAnchor.addEventListener('click', (event) => {
      event.stopPropagation()
    })

    return optionElement
  }
}
