import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['countryCode', 'phone', 'fullPhone']

  declare countryCodeTarget: HTMLSelectElement
  declare phoneTarget: HTMLInputElement
  declare fullPhoneTarget: HTMLInputElement

  connect (): void {
    this.updatePlaceholder()
  }

  setFullPhone (): void {
    if (this.phoneTarget.value.length === 0) {
      this.fullPhoneTarget.value = ''

      return
    }

    this.fullPhoneTarget.value = `${this.countryCodeTarget.value} ${this.phoneTarget.value}`
  }

  updatePlaceholder (): void {
    const selectedOption = (this.countryCodeTarget[this.countryCodeTarget.selectedIndex] as HTMLOptionElement)

    this.phoneTarget.placeholder = selectedOption.dataset.placeholder ?? ''
  }
}
