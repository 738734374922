import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submitEnd ({ detail: { fetchResponse: { response }, success } }: { detail: { success: boolean, fetchResponse: any } }): void {
    if (success) {
      response.text().then(() => {
        const event = new Event('phone-verification:success')
        document.dispatchEvent(event)
      })
    }
  }
}
