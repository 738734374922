import { Controller } from '@hotwired/stimulus'

// NOTE: saves single state(changed or unchanged) for group of elements
//   dispatches event on state change
export default class extends Controller {
  static targets = ['input']
  static values = {
    changed: Boolean
  }

  declare changedValue: boolean
  declare readonly inputTargets: HTMLInputElement[]

  changeByValuePresence ({ target }: { target: { value: string } }): void {
    if (target.value.length > 0) {
      this.change()
    } else {
      this.unchange()
    }
  }

  change (): void {
    this.changedValue = true
  }

  unchange (): void {
    this.changedValue = false
  }

  changedValueChanged (): void {
    if (this.changedValue) {
      this.changedCallback()
    } else {
      this.unchangeCallback()
    }
  }

  changedCallback (): void {
    const event = new Event('states:changed')

    this.inputTargets.forEach(input => input.dispatchEvent(event))
  }

  unchangeCallback (): void {
    const event = new Event('states:unchanged')

    this.inputTargets.forEach(input => input.dispatchEvent(event))
  }
}
