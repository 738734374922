import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['visibilityToggleable', 'visibilityToggle']

  declare readonly visibilityToggleableTarget: HTMLElement
  declare readonly visibilityToggleTarget: HTMLElement

  connect (): void {
    this.element.addEventListener('visibility-toggle', _event => {
      this.changeSelfVisibility()
    })
  }

  changeSelfVisibility (): void {
    const classList = this.element.classList

    if (classList.contains('hidden')) {
      classList.remove('hidden')
    } else {
      classList.add('hidden')
    }
  }

  changeTargetVisibility (): void {
    this.visibilityToggleableTarget.classList.toggle('hidden')
    this.visibilityToggleTarget.classList.toggle('rotate-180')
  }
}
