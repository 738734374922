import { Controller } from '@hotwired/stimulus'

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
export default class extends Controller {
  static values = {
    types: Object,
    predicates: Object,
    values: Object,
    labels: Object
  }

  declare typesValue: any
  declare predicatesValue: any
  declare valuesValue: any
  declare labelsValue: any

  updatePredicates ({ target }: any): void {
    const valueType = this.typesValue[target.value]
    const predicates = this.translate(this.loadPredicates(target.value, valueType), target.value, valueType)
    const values = this.valuesValue[target.value] || this.valuesValue[valueType] || []
    const event = new CustomEvent('nested:update', {
      detail: {
        valueType,
        predicates,
        values
      }
    })
    target.dispatchEvent(event)
  }

  updateValues ({ target }: any): void {
    const fieldsId = `fields.${target.value as string}`
    const valueType = this.typesValue[fieldsId]
    const values = this.valuesValue[fieldsId] || this.valuesValue[valueType] || []

    const event = new CustomEvent('nested:update', {
      detail: {
        valueType,
        values
      }
    })

    target.dispatchEvent(event)
  }

  translate (predicates: any[], attribute: string, type: string): any[] {
    return predicates.map((id) => ({ name: this.label(attribute, type, id), id }))
  }

  loadPredicates (attribute: string, type: string): any[] {
    return this.predicatesValue[attribute] || this.predicatesValue[type] || []
  }

  label (attribute: string, type: string, predicate: string): string {
    return this.labelsValue[type]?.[predicate] || this.labelsValue[attribute]?.[predicate] || this.labelsValue[predicate]
  }
}
/* eslint-enable @typescript-eslint/strict-boolean-expressions */
