import { Controller } from '@hotwired/stimulus'
import Chart, { ChartItem, ChartConfiguration } from 'chart.js/auto'
import {
  ChartData,
  ChartType,
  LegendItem,
  PluginOptionsByType
} from 'chart.js/dist/types'

const pieLegendPlugin = {
  id: 'pieLegend',
  afterUpdate (chart: Chart, args: Object, options: any) {
    const ul = options.element

    while (ul.firstChild as boolean) {
      ul.firstChild.remove()
    }

    const items = ((chart.options).plugins as PluginOptionsByType<any>).legend.labels.generateLabels(chart)
    const data = chart.data.datasets[0].data

    let total = 0
    data.forEach((e: any) => {
      total += e as number
    })

    items.forEach((item: LegendItem) => {
      const value = data[item.index ?? 0] as number
      const percentage = (value * 100 / total).toFixed(0)

      const template = options.listTemplate.innerHTML
        .replace(/legend_row_name/, item.text)
        .replace(/legend_row_percentage/, percentage)
        .replace(/legend_row_color/, item.fillStyle)

      ul.insertAdjacentHTML('beforeend', template)
    })
  }
}

interface CustomPluginOptionsByType extends PluginOptionsByType<ChartType> {
  pieLegend?: pieLegendOptions
}

interface pieLegendOptions {
  element: HTMLDivElement
  listTemplate: HTMLDivElement
}

export default class extends Controller {
  static targets = [
    'canvas',
    'legend',
    'legendRowTemplate'
  ]

  static values = {
    rows: Array
  }

  declare rowsValue: any[]
  declare readonly canvasTarget: HTMLCanvasElement
  declare readonly legendTarget: HTMLDivElement
  declare readonly legendRowTemplateTarget: HTMLDivElement

  initialize (): void {
    const { names, counts, colors } = this.extractRows()

    const data: ChartData<any> = {
      labels: names,
      datasets: [
        {
          data: counts,
          cutout: '90%',
          borderWidth: 0,
          borderRadius: 0,
          spacing: 0,
          backgroundColor: colors
        }
      ]
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const plugins = {
      pieLegend: {
        element: this.legendTarget,
        listTemplate: this.legendRowTemplateTarget
      },
      legend: {
        display: false
      }
    } as CustomPluginOptionsByType

    const configuration: ChartConfiguration = {
      type: 'pie',
      data,
      options: {
        plugins
      },
      plugins: [pieLegendPlugin]
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const chart = new Chart(this.canvasTarget as ChartItem, configuration)
  }

  private extractRows (): { names: string[], counts: number[], colors: string[] } {
    const names: string[] = []
    const counts: number[] = []
    const colors: string[] = []

    this.rowsValue.forEach((row) => {
      const { name, count, color } = row

      names.push(name)
      counts.push(count)
      colors.push(color)
    })

    return { names, counts, colors }
  }
}
