import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'template',
    'container'
  ]

  static values = {
    regexp: String
  }

  declare readonly templateTarget: HTMLTemplateElement
  declare readonly containerTarget: HTMLDivElement
  declare readonly hasRegexpValue: boolean
  declare regexpValue: string

  connect (): void {
    document.addEventListener('statusChanged', () => this.toggleContainerVisibility())
    this.toggleContainerVisibility()
  }

  disconnect (): void {
    document.removeEventListener('statusChanged', () => this.toggleContainerVisibility())
  }

  add ({ detail: { id } }: CustomEvent): void {
    if (this.containerTarget.classList.contains('hidden')) {
      this.containerTarget.classList.remove('hidden')
    }

    let html = this.templateTarget.innerHTML

    if (this.hasRegexpValue) {
      const newId = `${id || new Date().getTime()}` // eslint-disable-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/restrict-template-expressions
      const templateId = new RegExp(this.regexpValue, 'g')

      html = html.replace(templateId, newId)
    }

    this.containerTarget.insertAdjacentHTML('beforeend', html)
  }

  getContainerChildren (): HTMLElement[] {
    const isContainerList = ['UL', 'OL', 'DL'].includes(this.containerTarget.tagName)
    const containerChildrenTagName = isContainerList ? 'LI' : 'DIV'

    return Array.from(this.containerTarget.children)
      .filter(child => child.tagName === containerChildrenTagName) as HTMLElement[]
  }

  isContainerEmpty (): boolean {
    return this.getContainerChildren().every(child => child.classList.contains('hidden'))
  }

  toggleContainerVisibility (): void {
    this.containerTarget.classList.toggle('hidden', this.isContainerEmpty())
  }
}
