import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

interface FlatpickrInputElement extends HTMLInputElement {
  _flatpickrInstance?: flatpickr.Instance
}

export default class extends Controller {
  static targets = ['input', 'projectInput']

  declare readonly inputTarget: FlatpickrInputElement

  connect (): void {
    this.update()
  }

  disconnect (): void {
    this.destroyDatepicker(this.inputTarget)
  }

  update (): void {
    setTimeout(() => {
      const inputTypes = ['date', 'hidden', 'datetime-local', 'datetime']
      // Handle inputTarget
      if (inputTypes.includes(this.inputTarget.type)) {
        this.initializeDatepicker(this.inputTarget)
      } else if (this.inputTarget._flatpickrInstance != null) {
        this.inputTarget.removeAttribute('data-original-type')
        this.destroyDatepicker(this.inputTarget)
      }
    }, 10)
  }

  initializeDatepicker (inputElement: FlatpickrInputElement): void {
    const enableTime = inputElement.dataset.datepickerEnableTime === 'true'
    inputElement.dataset.originalType = inputElement.type
    let dateFormat = 'Y-m-d'
    let altFormat = 'd/m/Y'

    if (enableTime) {
      dateFormat += ' H:i'
      altFormat += ' H:i'
    }

    const options: any = {
      enableTime,
      dateFormat,
      altFormat,
      altInput: true,
      allowInput: true,
      monthSelectorType: 'static',
      altInputClass: `${inputElement.className} flatpickr-input`,
      static: true
    }

    // TODO: need find a better way to show dates in past
    //   this is workaround for ScheduledWorkflows
    if (Date.parse(inputElement.value) < Date.parse(inputElement.min)) {
      options.enable = [
        inputElement.value,
        {
          from: inputElement.min,
          to: inputElement.max
        }
      ]
    } else {
      options.minDate = inputElement.min
    }

    inputElement._flatpickrInstance = flatpickr(inputElement, options)
  }

  destroyDatepicker (inputElement: FlatpickrInputElement): void {
    const datepicker = inputElement._flatpickrInstance

    if (datepicker != null) {
      datepicker.destroy()
      delete inputElement._flatpickrInstance

      inputElement.value = ''
      inputElement.type = inputElement.dataset.originalType !== undefined ? inputElement.dataset.originalType : 'text'
      inputElement.nextElementSibling?.remove()
    }
  }
}
