import { application } from './application'
import { Controller } from '@hotwired/stimulus'

import { Alert, ColorPreview, Tabs, Toggle, Slideover, Modal } from 'tailwindcss-stimulus-components'
import { ClearableCheckboxController, ClearableInputController, ClearableResetButtonController, ClearableStatesController, ClearableSelectController } from './clearable'
import { DashboardController, WidgetController, WidgetFormController, WidgetSimpleFormController, WidgetPieController, WidgetBarController, WidgetLineController } from './dashboards'
import { FacilityPreRegisterNotesController, FacilityNominateContactsController, FacilitiesFormController } from './facilities'
import { FieldsFormController, FieldsOptionController, FieldsNumericController } from './fields'
import { FormBuilderController, FormItemController, FormItemFormController, FormNewItemController, FormSectionController, FormResetInvalidController } from './forms'
import { LoginEnableAcknowledgeController, LoginSetCheckedController, TimeoutableSessionController } from './login'
import { Multiselect, Sortable, SortableLocal, PhoneNumberInputController, DatepickerController, PopoverController, DropdownController } from './ui'
import { NestedFormController, NestedConditionController, TemplatableController } from './nested'
import { PageViewController, CurrentPageViewController, PageViewSelectedColumnController } from './page_views'
import { ProjectTransitionFormController, ProjectBulkCopyController, ProjectCreationMessageController } from './projects'
import { QuestionnaireController, QuestionnaireQuestionController, QuestionnaireTableController } from './questionnaire'
import { RecordCounterOriginController, RecordCounterController } from './counters'
import { RefreshProgressBarController } from './bulk_actions'
import { RolesFormController, RolesSelectAllController } from './roles'
import { SearchController, AdvancedSearchController } from './search'
import { TestIntegrationController } from './integrations'
import { WorkflowsFormController, WorkflowsEventController, WorkflowsActionController, WorkflowsActionRecipientController, WorkflowsSimpleModeController, WorkflowsActionGroupController } from './workflows'

// @ts-expect-error
import Autosave from 'stimulus-rails-autosave'
import AdvancedFilterController from './advanced_filter_controller'
import AppearanceController from './appearance_controller'
import BlurableController from './blurable_controller'
import BulkActionController from './bulk_action_controller'
import BulkActionsMenuController from './bulk_actions_menu_controller'
import BulkArchivedActionsController from './bulk_archived_actions_controller'
import BulkItemController from './bulk_item_controller'
import BulkSelectController from './bulk_select_controller'
import ButtonToggleController from './button_toggle_controller'
import CascadingSelectController from './cascading_select_controller'
import ClickableRowController from './clickable_row_controller'
import ClipboardController from './clipboard_controller'
import CloneableAlertController from './cloneable_alert_controller'
import CollapsableController from './collapsable_controller'
import CollapseSidebarController from './collapse_sidebar_controller'
import ColorsSelectController from './colors_select_controller'
import CompaniesSelectController from './selectable/companies_select_controller'
import ControlAnswersStandardSelectorController from './control_answers_standard_selector_controller'
import CopyTextController from './copy_text_controller'
import CopyValueController from './copy_value_controller'
import CountCheckboxesController from './count_checkboxes_controller'
import FieldDatabaseOptionsSelectController from './selectable/field_database_options_select_controller'
import DialogAlertController from './dialog_alert_controller'
import DirectUploadController from './direct_upload_controller'
import FacilitiesSelectController from './selectable/facilities_select_controller'
import FacilitiesFilterController from './selectable/facilities_filter_controller'
import OrganizationFacilitiesSelectController from './selectable/organization_facilities_select_controller'
import FileRemovableController from './file_removable_controller'
import FilterController from './filter_controller'
import FocusableController from './focusable_controller'
import FormResetController from './form_reset_controller'
import HiddenInputController from './hidden_input_controller'
import IconsSelectController from './icons_select_controller'
import ImagePreviewController from './image_preview_controller'
import ImageRemovalController from './image_removal_controller'
import ImportController from './import_controller'
import LinkToBulkSelectController from './link_to_bulk_select_controller'
import LinkToDropdownController from './link_to_dropdown_controller'
import LinkToModalController from './link_to_modal_controller'
import LinkToSlideoverController from './link_to_slideover_controller'
import ListToggleController from './list_toggle_controller'
import ModalsContainerController from './modals_container_controller'
import NavigatableController from './navigatable_controller'
import NestableController from './nestable_controller'
import NotificationTemplatesFormController from './notification_templates/form_controller'
import PasswordInputController from './password_input_controller'
import PhoneVerificationController from './phone_verification_controller'
import PhoneVerificationModalController from './phone_verification_modal_controller'
import QueryFormController from './query_form_controller'
import RemovableController from './removable_controller'
import ResetSearchController from './reset_search_controller'
import RolePermissionsController from './role_permissions_controller'
import SearchOptionsController from './search_options_controller'
import SelectableDropdownController from './selectable_dropdown_controller'
import SetActiveController from './set_active_controller'
import ShowMoreController from './show_more_controller'
import StatusesTransitionsController from './statuses_transitions_controller'
import SubmitController from './submit_controller'
import SubmitOnEnterController from './submit_on_enter_controller'
import ToggleableController from './toggleable_controller'
import ToggleDisableController from './toggle_disable_controller'
import ToggleOutletController from './toggle_outlet_controller'
import TransitionsController from './transitions_controller'
import TurboFormController from './turbo_form_controller'
import UserFilterController from './user_filter_controller'
import UsersMultiselectController from './users_multiselect_controller'
import VisibilityTargetController from './visibility_target_controller'
import VisibilityToggleController from './visibility_toggle_controller'

application.register('advanced-filter', AdvancedFilterController)
application.register('advanced-search', AdvancedSearchController)
application.register('alert', Alert)
application.register('appearance', AppearanceController)
application.register('autosave', Autosave)
application.register('blurable', BlurableController)
application.register('bulk-action', BulkActionController)
application.register('bulk-actions-menu', BulkActionsMenuController)
application.register('bulk-archived-actions', BulkArchivedActionsController)
application.register('bulk-item', BulkItemController)
application.register('bulk-select', BulkSelectController)
application.register('button-toggle', ButtonToggleController)
application.register('cascading-select', CascadingSelectController)
application.register('clearable-checkbox', ClearableCheckboxController)
application.register('clearable-input', ClearableInputController)
application.register('clearable-reset-button', ClearableResetButtonController)
application.register('clearable-select', ClearableSelectController)
application.register('clearable-states', ClearableStatesController)
application.register('clickable-row', ClickableRowController)
application.register('clipboard', ClipboardController)
application.register('cloneable-alert', CloneableAlertController)
application.register('collapsable', CollapsableController)
application.register('collapse-sidebar', CollapseSidebarController)
application.register('color-preview', ColorPreview)
application.register('colors-select', ColorsSelectController)
application.register('companies-select', CompaniesSelectController)
application.register('control-answers-standard-selector', ControlAnswersStandardSelectorController)
application.register('copy-text', CopyTextController)
application.register('copy-value', CopyValueController)
application.register('count-checkboxes', CountCheckboxesController)
application.register('current-page-view', CurrentPageViewController)
application.register('dashboard', DashboardController)
application.register('datepicker', DatepickerController)
application.register('field-database-options-select', FieldDatabaseOptionsSelectController)
application.register('dialog-alert', DialogAlertController)
application.register('direct-upload', DirectUploadController)
application.register('dropdown', DropdownController as unknown as typeof Controller)
application.register('external-tabs', Tabs)
application.register('facilities-form', FacilitiesFormController)
application.register('facilities-select', FacilitiesSelectController)
application.register('facilities-filter', FacilitiesFilterController)
application.register('facility-nominate-contacts', FacilityNominateContactsController)
application.register('facility-pre-register-notes', FacilityPreRegisterNotesController)
application.register('fields-form', FieldsFormController)
application.register('fields-numeric', FieldsNumericController)
application.register('fields-option', FieldsOptionController)
application.register('file-removable', FileRemovableController)
application.register('filter', FilterController)
application.register('focusable', FocusableController)
application.register('form-builder', FormBuilderController)
application.register('form-item-form', FormItemFormController)
application.register('form-item', FormItemController)
application.register('form-new-item', FormNewItemController)
application.register('form-reset-invalid', FormResetInvalidController)
application.register('form-section', FormSectionController)
application.register('form-reset', FormResetController)
application.register('hidden-input', HiddenInputController)
application.register('icons-select', IconsSelectController)
application.register('image-preview', ImagePreviewController)
application.register('image-removal', ImageRemovalController)
application.register('import', ImportController)
application.register('link-to-bulk-select', LinkToBulkSelectController)
application.register('link-to-dropdown', LinkToDropdownController)
application.register('link-to-modal', LinkToModalController)
application.register('link-to-slideover', LinkToSlideoverController)
application.register('list-toggle', ListToggleController)
application.register('login-enable-acknowledge', LoginEnableAcknowledgeController)
application.register('login-set-checked', LoginSetCheckedController)
application.register('modal', Modal as unknown as typeof Controller)
application.register('modals-container', ModalsContainerController)
application.register('multiselect', Multiselect)
application.register('navigatable', NavigatableController)
application.register('nestable', NestableController)
application.register('nested-condition', NestedConditionController)
application.register('nested-form', NestedFormController)
application.register('notification-templates-form', NotificationTemplatesFormController)
application.register('organization-facilities-select', OrganizationFacilitiesSelectController)
application.register('page-view-selected-column', PageViewSelectedColumnController)
application.register('page-view', PageViewController)
application.register('password-input', PasswordInputController)
application.register('phone-number-input', PhoneNumberInputController)
application.register('phone-verification-modal', PhoneVerificationModalController)
application.register('phone-verification', PhoneVerificationController)
application.register('popover', PopoverController)
application.register('project-bulk-copy', ProjectBulkCopyController)
application.register('project-creation-message', ProjectCreationMessageController)
application.register('project-transition-form', ProjectTransitionFormController)
application.register('query-form', QueryFormController)
application.register('questionnaire-question', QuestionnaireQuestionController)
application.register('questionnaire-table', QuestionnaireTableController)
application.register('questionnaire', QuestionnaireController)
application.register('record-counter-origin', RecordCounterOriginController)
application.register('record-counter', RecordCounterController)
application.register('refresh-progress-bar', RefreshProgressBarController)
application.register('removable', RemovableController)
application.register('reset-search', ResetSearchController)
application.register('role-permissions', RolePermissionsController)
application.register('roles-form', RolesFormController)
application.register('roles-select-all', RolesSelectAllController)
application.register('search-options', SearchOptionsController)
application.register('search', SearchController)
application.register('selectable-dropdown', SelectableDropdownController)
application.register('set-active', SetActiveController)
application.register('show-more', ShowMoreController)
application.register('slideover', Slideover)
application.register('sortable-local', SortableLocal)
application.register('sortable', Sortable)
application.register('statuses-transitions', StatusesTransitionsController)
application.register('submit-on-enter', SubmitOnEnterController)
application.register('submit', SubmitController)
application.register('tabs', Tabs)
application.register('templatable', TemplatableController)
application.register('test-integration', TestIntegrationController)
application.register('timeoutable-session', TimeoutableSessionController)
application.register('toggle-disable', ToggleDisableController)
application.register('toggle-outlet', ToggleOutletController)
application.register('toggle', Toggle)
application.register('toggleable', ToggleableController)
application.register('transitions', TransitionsController)
application.register('turbo-form', TurboFormController)
application.register('user-filter', UserFilterController)
application.register('users-multiselect', UsersMultiselectController)
application.register('visibility-target', VisibilityTargetController)
application.register('visibility-toggle', VisibilityToggleController)
application.register('widget-bar', WidgetBarController)
application.register('widget-form', WidgetFormController)
application.register('widget-line', WidgetLineController)
application.register('widget-pie', WidgetPieController)
application.register('widget-simple-form', WidgetSimpleFormController)
application.register('widget', WidgetController)
application.register('workflows-action-group', WorkflowsActionGroupController)
application.register('workflows-action-recipient', WorkflowsActionRecipientController)
application.register('workflows-action', WorkflowsActionController)
application.register('workflows-event', WorkflowsEventController)
application.register('workflows-form', WorkflowsFormController)
application.register('workflows-simple-mode', WorkflowsSimpleModeController)
