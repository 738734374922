import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLDivElement> {
  static outlets = ['page-view', 'page-view-selected-column']

  declare readonly pageViewOutletElement: HTMLElement
  declare readonly pageViewSelectedColumnOutletElements: HTMLInputElement[]

  get pageViewId (): string {
    return this.pageViewOutletElement.dataset.id as string
  }

  get selectedColumns (): string[] {
    this.element.dataset.currentPageViewPageViewSelectedColumnOutlet = `.page-view-${this.pageViewId}-column:checked`
    return this.pageViewSelectedColumnOutletElements.map(checkbox => checkbox.value)
  }
}
