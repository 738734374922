import { GridStackNode } from 'gridstack'
import { railsRequest } from '../modules/helpers'

interface QuestionAttributes {
  id: number | string
  position: number | undefined
}

export default class Dashboard {
  id: number

  constructor (id: number) {
    this.id = id
  }

  save (nodes: GridStackNode[]): void {
    const attributes: { [key: number]: QuestionAttributes } = {}

    nodes.forEach((node: GridStackNode, index: number) => {
      const { id, y } = node

      if (id !== undefined) {
        attributes[index] = { id, position: y }
      }
    })

    void railsRequest(`/settings/questionnaires/${this.id}`, {
      method: 'patch',
      data: { questionnaire: { questions_attributes: attributes } }
    })
  }
}
