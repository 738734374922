import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'

export default class extends Controller<HTMLLinkElement> {
  static targets = ['button']

  static values = {
    open: Boolean
  }

  declare readonly buttonTarget: HTMLElement
  declare readonly hasButtonTarget: boolean
  declare openValue: boolean

  initialize (): void {
    if (this.openValue) {
      setTimeout(() => {
        const button = this.hasButtonTarget ? this.buttonTarget : this.element
        button.click()
      }, 100)
    }
  }

  navigate ({ currentTarget }: { currentTarget: HTMLLinkElement }): void {
    this.navigateTo({ params: { url: currentTarget.href } })
  }

  formNavigate ({ detail: { fetchResponse: { response: { url } } } }: { detail: { fetchResponse: { response: { url: string } } } }): void {
    this.navigateTo({ params: { url } })
  }

  navigateTo ({ params: { url } }: { params: { url: string } }): void {
    Turbo.navigator.history.replace(new URL(url, location.origin))
  }

  navigateToCurrentAddParam ({ params: { name, value } }: { params: { name: string, value: string } }): void {
    const url = new URL(location.href)

    url.searchParams.set(name, value)

    Turbo.navigator.history.replace(url)
  }

  navigateToCurrentDelParam ({ params: { name } }: { params: { name: string } }): void {
    const url = new URL(location.href)

    url.searchParams.delete(name)

    Turbo.navigator.history.replace(url)
  }
}
