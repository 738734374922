import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  declare readonly checkboxTargets: HTMLInputElement[]

  toggle ({ target, params: { name } }: { target: HTMLInputElement, params: { name: String } }): void {
    this.checkboxTargets.filter((checkbox) => name === undefined || checkbox.name === name)
      .forEach((checkbox) => { checkbox.checked = target.checked })
  }
}
