import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['record-counter-origin']
  static values = { counted: Number }

  declare readonly recordCounterOriginOutlet: any
  declare readonly hasRecordCounterOriginOutlet: boolean
  declare readonly countedValue: number

  connect (): void {
    if (this.hasRecordCounterOriginOutlet) {
      this.recordCounterOriginOutlet.element.textContent = this.countedValue
    }
  }
}
