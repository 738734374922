import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['authority', 'company', 'auditor', 'type']

  declare readonly authorityTarget: HTMLDivElement
  declare readonly companyTarget: HTMLDivElement
  declare readonly auditorTarget: HTMLDivElement
  declare readonly typeTarget: HTMLSelectElement

  initialize (): void {
    this.changeType()
  }

  changeType (): void {
    this.toggle(this.authorityTarget, this.typeTarget.value === 'AuthorityRole')
    this.toggle(this.companyTarget, this.typeTarget.value === 'CompanyRole')
    this.toggle(this.auditorTarget, this.typeTarget.value === 'AuditorRole')
  }

  private readonly toggle = (target: HTMLDivElement, show: boolean): void => {
    target.classList.toggle('hidden', !show)
    target.querySelectorAll('input, textarea')
      .forEach((input: any) => { input.disabled = !show })
  }
}
