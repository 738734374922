import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'button']

  declare readonly buttonTargets: HTMLButtonElement[]
  declare readonly inputTargets: HTMLInputElement[]

  update (): void {
    const inputs: HTMLInputElement[] = this.selectedInputs

    this.buttonTargets.forEach((button: HTMLButtonElement) => {
      const { dataset: { status } } = button

      button.classList.toggle('hidden', this.hasInputWithDifferentStatus(inputs, status))
    })
  }

  private get selectedInputs (): HTMLInputElement[] {
    return Array.from(this.inputTargets).filter(input => input.checked)
  }

  private hasInputWithDifferentStatus (inputs: HTMLInputElement[], requiredStatus: string | undefined): boolean {
    if (requiredStatus === undefined) {
      return false
    }

    const input = inputs.find((input) => {
      const { dataset: { status } } = input

      return !(status === undefined || requiredStatus.includes(status))
    })

    return input !== undefined
  }
}
