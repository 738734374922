import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'tableInput', 'rowContainer', 'cellTemplate',
    'cellContainer', 'rowActionsTemplate', 'columnActionsTemplate'
  ]

  static values = {
    editable: Boolean
  }

  declare readonly tableInputTarget: HTMLInputElement
  declare readonly rowContainerTarget: HTMLElement
  declare readonly cellTemplateTarget: HTMLElement
  declare readonly cellContainerTargets: HTMLElement[]
  declare readonly rowActionsTemplateTarget: HTMLElement
  declare readonly columnActionsTemplateTarget: HTMLElement
  declare readonly editableValue: boolean

  connect (): void {
    this.updateTableInput()
    this.updateActions()
  }

  updateTableInput (): void {
    const tableData = this.cellContainerTargets.map((row: HTMLElement) => {
      return Array.from(row.querySelectorAll('.questionnaire-table-cell'))
        .map((cell) => (cell as HTMLElement).innerText.trim())
    })
    this.tableInputTarget.value = JSON.stringify(tableData)

    this.checkIfEmpty()
    this.updateDropdowns()
  }

  addRow (): void {
    const columns = Array.from(this.element.querySelectorAll('thead tr th'))
    const newRow = document.createElement('tr')

    if (columns.length > 0) {
      columns.forEach(() => newRow.insertAdjacentHTML('beforeend', this.cellTemplateTarget.innerHTML))
      newRow.setAttribute('data-questionnaire-table-target', 'cellContainer')
      newRow.insertAdjacentHTML('afterbegin', this.rowActionsTemplateTarget.innerHTML)
      this.rowContainerTarget.append(newRow)
    } else {
      this.createTable()
    }

    this.updateTableInput()
  }

  addColumn (): void {
    const headerRow = this.element.querySelector('thead tr')
    const rows = Array.from(this.rowContainerTarget.querySelectorAll('tr'))

    if (rows.length > 0) {
      rows.forEach(row => row.insertAdjacentHTML('beforeend', this.cellTemplateTarget.innerHTML))
      headerRow?.insertAdjacentHTML('beforeend', this.columnActionsTemplateTarget.innerHTML)
    } else {
      this.createTable()
    }

    this.updateTableInput()
  }

  deleteColumn (event: Event): void {
    this.deleteElement(event, true)
  }

  deleteRow (event: Event): void {
    this.deleteElement(event, false)
  }

  private createTable (): void {
    const headerRow = this.element.querySelector('thead tr')
    const newRow = document.createElement('tr')

    newRow.insertAdjacentHTML('afterbegin', this.rowActionsTemplateTarget.innerHTML)
    newRow.insertAdjacentHTML('beforeend', this.cellTemplateTarget.innerHTML)
    newRow.setAttribute('data-questionnaire-table-target', 'cellContainer')
    this.rowContainerTarget.append(newRow)
    this.editableValue && headerRow?.insertAdjacentHTML('beforeend', this.columnActionsTemplateTarget.innerHTML)
  }

  private updateActions (): void {
    const tHeads = Array.from(this.element.querySelectorAll('tr th'))

    tHeads.forEach((tHead) => {
      if (tHead.querySelector(':scope > .column-actions-template') == null && tHead.classList.contains('column-actions-template') && this.editableValue) {
        tHead.insertAdjacentHTML('beforeend', this.columnActionsTemplateTarget.innerHTML)
        tHead.classList.replace('column-actions-template', 'column-actions')
      } else if (tHead.querySelector(':scope > .row-actions-template') == null && tHead.classList.contains('row-actions-template')) {
        tHead.insertAdjacentHTML('afterbegin', this.rowActionsTemplateTarget.innerHTML)
        tHead.classList.replace('row-actions-template', 'row-actions')
      }
    })
  }

  private deleteElement (event: any, isColumn: boolean): void {
    const element = event.target.closest(isColumn ? 'th' : 'tr')
    const index = Array.from(element.parentNode.children).indexOf(element)
    const headerRow = this.element.querySelector('thead tr')
    const rows = Array.from(this.rowContainerTarget.querySelectorAll('tr'))

    if (isColumn) {
      headerRow?.children[index].remove()
      rows.forEach(row => row.children[index].remove())
    } else {
      element.remove()
    }

    this.updateTableInput()
  }

  private checkIfEmpty (): void {
    const isEmpty = this.element.querySelectorAll('.questionnaire-table-cell').length === 0

    if (isEmpty) {
      this.clearTable()
    }
  }

  private updateDropdowns (): void {
    const theads = Array.from(this.element.querySelectorAll('tr th'))

    setTimeout(() => {
      theads.forEach((th) => {
        th?.firstElementChild?.setAttribute('data-dropdown-adaptive-value', 'false')
      })
    }, 0)
  }

  private clearTable (): void {
    const theads = Array.from(this.element.querySelectorAll('tr th'))
    const tbody = this.element.querySelector('tbody')

    theads.forEach((thead) => {
      thead.remove()
    })

    while ((tbody?.firstChild) != null) {
      tbody.removeChild(tbody.firstChild)
    }
  }
}
