import { Controller } from '@hotwired/stimulus'

// NOTE: this controller is only for submitting bulk form with selected ids
//   - find real selected checkbox inputs from page table
//   - create fake checkbox inputs inside this controller element form
//   - submit form
export default class extends Controller<HTMLFormElement> {
  static targets = ['input']
  static outlets = ['bulk-item']

  declare readonly inputTargets: HTMLInputElement[]
  declare readonly bulkItemOutletElements: HTMLInputElement[]

  submit (e: any): void {
    e.preventDefault()

    if (this.confirm(e)) {
      this.removeInputs()
      this.addInputs()
      setTimeout(() => this.element.requestSubmit())
    }
  }

  private confirm ({ target: { dataset: { message } } }: { target: { dataset: { message: string | undefined } } }): boolean {
    return message === undefined || message.length === 0 || confirm(message)
  }

  private removeInputs (): void {
    this.inputTargets.forEach((input) => {
      input.remove()
    })
  }

  private addInputs (): void {
    this.bulkItemOutletElements.filter(e => e.checked).forEach((checkbox) => {
      this.element.appendChild(this.buildInput(checkbox))
    })
  }

  private buildInput (checkbox: HTMLInputElement): HTMLInputElement {
    const input = document.createElement('input')

    input.type = 'hidden'
    input.name = checkbox.name
    input.value = checkbox.value
    input.dataset.bulkActionTarget = 'input'

    return input
  }
}
