import { Controller } from '@hotwired/stimulus'

// HACK: this is a workaround to show the alert in open dialog after turbo request
export default class extends Controller {
  backdropClose (event: any): void {
    if (event.target.nodeName === 'DIALOG') {
      this.dispatch('close')
    }
  }
}
