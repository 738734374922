import { Controller } from '@hotwired/stimulus'

const BLUR_TIMEOUT_MS = 600

export default class extends Controller {
  blurWithTimeout (): void {
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    }, BLUR_TIMEOUT_MS)
  }
}
