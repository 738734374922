import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['searchInput', 'option', 'selectAll']

  declare readonly searchInputTarget: HTMLInputElement
  declare readonly optionTargets: HTMLElement[]
  declare readonly selectAllTarget: HTMLInputElement
  declare readonly hasSelectAllTarget: boolean
  declare selectAllState: boolean

  connect (): void {
    this.selectAllState = false
    this.updateSelectAllCheckbox()
  }

  filter (): void {
    const lowerCaseQuery = this.searchInputTarget.value.toLowerCase()

    this.optionTargets.forEach((option: HTMLElement) => {
      const optionText = option.textContent?.trim().toLowerCase() ?? ''
      const shouldBeVisible = optionText.includes(lowerCaseQuery)

      shouldBeVisible ? option.classList.remove('hidden') : option.classList.add('hidden')
    })

    this.updateSelectAllCheckbox()
  }

  selectAll (): void {
    this.selectAllState = !this.selectAllState

    this.optionTargets.forEach((option: HTMLElement) => {
      if (!option.classList.contains('hidden')) {
        const checkbox = option.querySelector<HTMLInputElement>('input[type="checkbox"]')
        if (checkbox != null) {
          checkbox.checked = this.selectAllState
        }
      }
    })

    this.updateSelectAllCheckbox()
  }

  private updateSelectAllCheckbox (): void {
    const visibleOptions = this.optionTargets.filter(option => !option.classList.contains('hidden'))
    const allChecked = visibleOptions.every(option => {
      const checkbox = option.querySelector<HTMLInputElement>('input[type="checkbox"]')

      return checkbox?.checked ?? false
    })

    if (this.hasSelectAllTarget) this.selectAllTarget.checked = allChecked
    this.selectAllState = allChecked
  }
}
