import { Controller } from '@hotwired/stimulus'
import Chart, { ChartItem, ChartConfiguration } from 'chart.js/auto'

export default class extends Controller {
  static values = {
    rows: Array
  }

  declare rowsValue: any[]

  initialize (): void {
    const { names, counts } = this.extractRows()

    const data = {
      labels: names,
      datasets: [
        {
          data: counts
        }
      ]
    }
    const configuration: ChartConfiguration = {
      type: 'line',
      data,
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const chart = new Chart(this.element as ChartItem, configuration)
  }

  private extractRows (): { names: string[], counts: number[] } {
    const names: string[] = []
    const counts: number[] = []

    this.rowsValue.forEach((row) => {
      const { name, count } = row

      names.push(name)
      counts.push(count)
    })

    return { names, counts }
  }
}
