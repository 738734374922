import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectedValue', 'required']

  static values = {
    selected: Array,
    multiple: Boolean
  }

  declare readonly selectedValueTarget: HTMLElement
  declare readonly requiredTarget: HTMLSelectElement
  declare selectedValue: string[]
  declare multipleValue: boolean

  collapsedValue: boolean

  select ({ target: { checked }, params: { name } }: { target: { checked: boolean }, params: { name: string } }): void {
    this.selectedValue = this.toggleValue(this.previousSelectedValue(), name, checked)
    this.collapsedValue = !this.collapsedValue
  }

  selectedValueChanged (): void {
    this.selectedValueTarget.innerHTML = this.selectedValueText()
    this.requiredTarget.disabled = this.selectedValue.length > 0
  }

  selectedValueText (): string {
    if (this.selectedValue.length > 0) {
      return this.selectedValue.join(', ')
    } else {
      return 'None selected'
    }
  }

  previousSelectedValue (): string[] {
    if (this.multipleValue) {
      return this.selectedValue
    } else {
      return []
    }
  }

  toggleValue = (arr: string[], value: string, enabled: boolean): string[] => {
    if (enabled) {
      return [...arr, value]
    }
    return arr.filter((id) => id !== value)
  }
}
