import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
  static targets = ['newLink']
  static values = {
    url: String
  }

  declare readonly newLinkTarget: HTMLLinkElement
  declare readonly urlValue: any

  connect (): void {
    const url = new URL(location.href)
    const selectedValue = Number(url.searchParams.get('standard_id'))

    if (!isNaN(selectedValue) && selectedValue !== 0) {
      this.updateNewUrl(selectedValue)
    }
  }

  standardTabChanged (event: Event): void {
    const selectElement = event.target as HTMLSelectElement
    const selectedValue = Number(selectElement.value)

    this.updateNewUrl(selectedValue)
    this.navigate(selectedValue)
  }

  navigate (selectedValue: Number): void {
    const newUrl = new URL(location.href)
    newUrl.searchParams.set('standard_id', selectedValue.toString())

    Turbo.navigator.history.replace(newUrl)
  }

  private updateNewUrl (selectedValue: Number): void {
    const newUrl = new URL(this.newLinkTarget.href)

    newUrl.searchParams.set('standard_id', selectedValue.toString())
    this.newLinkTarget.setAttribute('href', newUrl.toString())
  }
}
