import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/src/tom-select.popular'
import checkboxOptions from 'tom-select/src/plugins/checkbox_options/plugin'
import removeButton from 'tom-select/src/plugins/remove_button/plugin'

TomSelect.define('checkbox_options', checkboxOptions)
TomSelect.define('remove_button', removeButton)

export default class extends Controller {
  static targets = ['requirement']

  declare requirementTargets: HTMLInputElement[]

  plugin: TomSelect

  addRequirements ({ target: { tomselect: { items } } }: { target: { tomselect: { items: string[] } } }): void {
    this.requirementTargets.forEach((target: HTMLInputElement) => {
      target.value = items.some((item: string) => target.name.includes(item)).toString()
    })
  }
}
