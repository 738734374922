import { Controller } from '@hotwired/stimulus'

// NOTE: allows to add border to show/hide reset button
export default class extends Controller {
  touch (): void {
    this.element.classList.remove('hidden')
  }

  reset (): void {
    this.element.classList.add('hidden')
  }
}
