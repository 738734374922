import { Controller } from '@hotwired/stimulus'
// @ts-expect-error
import Nestable from 'nestablejs/src'

export default class extends Controller {
  static targets = ['input']

  instance: Nestable

  initialize (): void {
    this.instance = new Nestable(
      this.element,
      {
        items: '.item',
        collapseButtonContent: '',
        expandButtonContent: '',
        classes: {
          handle: 'sortable-handle'
        }
      }
    )
    this.instance.on('stop', this.stop.bind(this))
  }

  add (e: any): void {
    this.instance.add(e.target)
  }

  stop (data: any): void {
    new Set([data.originalParent, data.newParent]).forEach((parentNode) => {
      this.getSiblingsAndSelf(parentNode.firstChild).forEach((option: any, index: any) => {
        const event = new CustomEvent('nestable:reorder', {
          detail: {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            position: index + 1
          }
        })

        option.dispatchEvent(event)
      })
    })

    const event = new CustomEvent('nestable:stop', {
      detail: {
        parentOption: data.newParentItem
      }
    })
    data.movedNode.dispatchEvent(event)
  }

  getSiblingsAndSelf = (sibling: any): any => {
    const siblings = []

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    while (sibling) {
      if (sibling.nodeType === 1) {
        siblings.push(sibling)
      }
      sibling = sibling.nextSibling
    }

    return siblings
  }
}
