import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  value: string

  connect (): void {
    this.value = (this.element as HTMLInputElement).value
  }

  sanitize (): void {
    const input = this.element as HTMLInputElement
    const regex = /^[+-]?\d*\.?\d*$/

    if (regex.test(input.value)) {
      this.value = input.value
    } else {
      const { selectionStart } = input

      input.value = this.value

      if (selectionStart != null) {
        input.setSelectionRange(selectionStart - 1, selectionStart - 1)
      }
    }
  }
}
