import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submitEnd ({ detail: { success } }: { detail: { success: boolean } }): void {
    if (success) {
      const event = new Event('turbo-form:submit-end-success')

      this.element.dispatchEvent(event)
    }
  }
}
