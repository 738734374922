import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'tailwindcss-stimulus-components/src/transition'

export default class extends Controller {
  static targets = ['input', 'selected', 'fileName', 'selector', 'submit', 'inProgress', 'result']

  declare readonly inputTarget: HTMLInputElement
  declare readonly selectedTarget: HTMLElement
  declare readonly fileNameTarget: HTMLElement
  declare readonly selectorTarget: HTMLElement
  declare readonly submitTarget: HTMLInputElement
  declare readonly inProgressTarget: HTMLElement
  declare readonly resultTarget: HTMLElement

  form: HTMLFormElement

  connect (): void {
    this.form = this.inputTarget.closest('form') as HTMLFormElement
  }

  select ({ dataTransfer }: { dataTransfer: DataTransfer | null }): void {
    if (dataTransfer != null) {
      this.addFileToInput(dataTransfer.files?.item(0))
    }

    this.updateElements()

    this.submitTarget.focus()
  }

  reset (): void {
    this.form.reset()

    this.updateElements()

    enter(this.form)
    leave(this.inProgressTarget)

    this.resultTarget.innerHTML = ''
  }

  dragOver (e: Event): void {
    e.preventDefault()
  }

  showInProgress ({ detail: { formSubmission } }: CustomEvent): void {
    formSubmission.location.search = location.search

    const pageViewsIndex = location.pathname.search(/\/page_views\/\d+$/g)
    if (pageViewsIndex > -1) {
      formSubmission.location.searchParams.set('page_view_id', location.pathname.substring(pageViewsIndex + 12))
    }

    leave(this.form)
    enter(this.inProgressTarget)
  }

  hideInProgress (): void {
    leave(this.inProgressTarget)
  }

  private updateElements (): void {
    const fileIsAdded = (this.inputTarget?.files ?? []).length > 0

    this.selectedTarget.classList.toggle('hidden', !fileIsAdded)
    this.selectorTarget.classList.toggle('hidden', fileIsAdded)
    this.submitTarget.disabled = !fileIsAdded

    if (fileIsAdded) {
      this.fileNameTarget.innerText = this.inputTarget.files?.item(0)?.name ?? ''
    }
  }

  private addFileToInput (file: File | null): void {
    if (file == null) return

    const data = new DataTransfer()

    data.items.add(file)

    this.inputTarget.files = data.files
  }
}
