import { Controller } from '@hotwired/stimulus'
import { Dropdown } from 'tailwindcss-stimulus-components'
import { createPopper, Instance } from '@popperjs/core/lib/popper-lite'

export default class extends Controller {
  static values = {
    placement: { type: String, default: 'bottom-end' }
  }

  static outlets = ['dropdown']

  declare readonly dropdownOutlet: Dropdown
  declare readonly dropdownOutletElement: HTMLDivElement
  declare placementValue: 'bottom-end' | 'bottom-start'

  popperInstance: Instance

  connect (): void {
    setTimeout(() => {
      this.createPopperInstance()
    }, 0)
  }

  show (e: Event): void {
    if (this.dropdownOutlet.openValue as boolean) {
      return
    }

    setTimeout(() => {
      this.dropdownOutlet.show(e)
      void this.popperInstance.update()
    }, 0)

    this.addIndent()
    this.flipInTable()
  }

  toggle (e: Event): void {
    setTimeout(() => {
      this.dropdownOutlet.toggle(e)
      void this.popperInstance.update()
    }, 0)

    this.addIndent()
  }

  private flipInTable (): void {
    setTimeout(() => {
      const menu = this.dropdownOutlet.menuTarget
      const table = this.element.closest('table')

      if (table != null && menu != null) {
        const tableRect = table.getBoundingClientRect()
        const dropdownRect = menu.getBoundingClientRect()
        const spaceBelow = tableRect.bottom - dropdownRect.bottom
        const spaceAbove = dropdownRect.top - tableRect.top

        if (spaceBelow < dropdownRect.height && spaceAbove > dropdownRect.height * 1.5) {
          (menu as HTMLElement).style.top = `-${(dropdownRect.height as number) + 45}px`
        }
      }
    }, 0)
  }

  private addIndent (): void {
    if (this.dropdownOutlet.menuTarget.firstElementChild !== null) {
      (this.dropdownOutlet.menuTarget.firstElementChild as HTMLElement).style.marginTop = '-16px'
    }
  }

  private createPopperInstance (): void {
    this.popperInstance = createPopper(
      this.element,
      this.dropdownOutletElement, {
        placement: this.placementValue,
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altAxis: true
            }
          },
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false
            }
          }
        ]
      }
    )
  }
}
