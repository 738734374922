import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parentNestableId', 'position']

  declare readonly parentNestableIdTarget: HTMLInputElement
  declare readonly positionTarget: HTMLInputElement

  move ({ detail: { parentOption } }: { detail: { parentOption: any } }): void {
    if (parentOption !== null) {
      this.parentNestableIdTarget.value = parentOption.querySelector('.nestable-id').value
    } else {
      this.parentNestableIdTarget.value = ''
    }
  }

  reorder ({ detail: { position } }: { detail: { position: string } }): void {
    this.positionTarget.value = position
  }
}
