import { Controller } from '@hotwired/stimulus'
import { GridStack, GridStackOptions, GridStackElement, GridItemHTMLElement, GridStackNode } from 'gridstack'
import { Questionnaire } from '../../models'

const gridstackOptions: GridStackOptions = {
  acceptWidgets: true,
  animate: false,
  column: 1,
  cellHeight: 84,
  disableResize: true,
  float: false,
  margin: 6,
  draggable: {
    handle: '.gridstack-draggable-handle'
  }
}

export default class extends Controller {
  static targets = ['container', 'question']
  static values = {
    id: Number
  }

  declare idValue: number
  declare readonly containerTarget: GridStackElement

  questionnaire: Questionnaire
  saveQuestionnaire: boolean
  gridstack: GridStack

  initialize (): void {
    this.questionnaire = new Questionnaire(this.idValue)
    this.saveQuestionnaire = true
    this.gridstack = GridStack.init(gridstackOptions, this.containerTarget)

    GridStack.setupDragIn('.group.is-picker .grid-stack-item', { appendTo: 'body', helper: 'clone' })
  }

  connect (): void {
    this.gridstack.on('added', this.addQuestions.bind(this))
    this.gridstack.on('change', this.sortQuestions.bind(this))
    this.gridstack.on('removed', this.removeQuestions.bind(this))
  }

  private addQuestions (_event: Event, nodes: GridStackNode[]): void {
    this.saveQuestionnaire = false

    setTimeout(() => {
      nodes.forEach((node) => this.addQuestion(node))
    }, 0)
  }

  private sortQuestions (_event: Event, nodes: GridStackNode[]): void {
    if (nodes === undefined) {
      return
    }

    if (this.saveQuestionnaire) {
      void this.questionnaire.save(nodes)
    } else {
      this.saveQuestionnaire = true
    }
  }

  private removeQuestions (): void {
    this.saveQuestionnaire = false
  }

  private addQuestion (node: GridStackNode): void {
    const { el, y } = node
    const event = new CustomEvent('item:added', { detail: { position: y } })

    if (el !== undefined) {
      el.dispatchEvent(event)
    }
  }

  private questionSaved ({ target: question, detail: { id } }: { target: GridItemHTMLElement, detail: { id: string | undefined } }): void {
    if (id !== undefined) {
      this.gridstack.update(question, { id })
      if (question.gridstackNode != null) {
        question.gridstackNode.id = id
      }
    }
  }

  private questionTargetDisconnected (question: GridItemHTMLElement): void {
    this.gridstack.removeWidget(question)
  }
}
