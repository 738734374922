import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'container',
    'condition'
  ]

  static values = {
    id: String
  }

  declare readonly containerTarget: HTMLDivElement
  declare readonly conditionTargets: HTMLDivElement[]
  declare removedValue: boolean

  remove (): void {
    this.removedValue = true
    const event = new Event('removable:remove')
    this.conditionTargets.forEach((condition) => condition.dispatchEvent(event))
  }
}
