import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'toggleButton']
  static values = { maxItems: Number }

  declare readonly itemTargets: HTMLElement[]
  declare readonly toggleButtonTarget: HTMLButtonElement
  declare maxItemsValue: number
  declare collapsed: boolean

  connect (): void {
    this.collapsed = true
    this.updateView()
  }

  toggle (): void {
    this.collapsed = !this.collapsed
    this.updateView()
  }

  updateView (): void {
    if (this.itemTargets.length <= this.maxItemsValue) {
      this.toggleButtonTarget.classList.add('hidden')
    }

    this.itemTargets.forEach((item, index) => {
      item.classList.toggle('hidden', this.collapsed && index >= this.maxItemsValue)
    })

    this.toggleButtonTarget.textContent = this.collapsed
      ? 'Show more'
      : 'Show less'
  }
}
