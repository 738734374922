import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']
  static values = {
    enabled: Boolean
  }

  declare readonly buttonTarget: HTMLInputElement
  declare enabledValue: boolean

  toggle (): void {
    this.enabledValue = !this.enabledValue
  }

  private enabledValueChanged (): void {
    if (this.enabledValue) {
      this.buttonTarget.classList.remove('disabled')
    } else {
      this.buttonTarget.classList.add('disabled')
    }
  }
}
