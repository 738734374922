import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['body']

  static values = {
    collapsed: Boolean
  }

  declare collapsedValue: boolean
  declare readonly bodyTarget: HTMLElement
  declare readonly hasBodyTarget: boolean

  toggle (): void {
    this.collapsedValue = !this.collapsedValue
  }

  show (): void {
    this.collapsedValue = false
  }

  hide (): void {
    this.collapsedValue = true
  }

  collapsedValueChanged (): void {
    if (this.hasBodyTarget) {
      if (this.collapsedValue) {
        this.bodyTarget.classList.add('hidden')
      } else {
        this.bodyTarget.classList.remove('hidden')
      }
    }
  }
}
