import { Controller } from '@hotwired/stimulus'
// @ts-expect-error
import Sortable from 'sortablejs'

export default class extends Controller {
  sortable: Sortable

  static values = {
    group: { type: String, default: 'default' }
  }

  declare groupValue: string

  connect (): void {
    const updatePositions = this.updatePositions.bind(this)

    this.sortable = Sortable.create(this.element, {
      group: this.groupValue,
      handle: '.sortable-handle',
      animation: 150,
      onSort: updatePositions
    })
  }

  updatePositions (): void {
    // FIXME: should load only current element children elements position inputs
    //   but now it loads all inherited children elements position inputs
    //   example: workflow action group - conditions and actions
    this.element.querySelectorAll('[id$="_position"]').forEach((input: Element, index: number) => {
      (input as HTMLInputElement).value = `${index + 1}`
    })
  }
}
